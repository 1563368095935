<script> 

    // @ts-nocheck
   import { onMount } from 'svelte';      
   import { modal } from "../../stores/modal"; 
   import { options } from "../../stores/options.js";  
   import { groups } from '../../stores/groups';
   import { fields } from "../../stores/form.js"; 
   import { settings } from '../../stores/settings';
   import InputSelect from "../inputSelect/InputSelect.svelte";
   import ButtonCancel from "../button/ButtonCancel.svelte";
   import ButtonOk from "../button/ButtonOk.svelte";
   export let callback;
   export let show = false;

   let components = {};


   /////////////////////////////////////////////////////////////////////////////////////////////////////////


   const handleCancel = () => {
       $modal.current = "none";
       callback("cancel");
   }

   const handleOk = () => {
        components["adminCompany"].update();
        $modal.current = "none";
        callback("ok");
   }

   /////////////////////////////////////////////////////////////////////////////////////////////////////////

   onMount(async () => {
       
   });

   /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


{#if show}

<div class="daa-backdrop daa-backdrop-dark">

   <div class="daa-dialog-container">

       <div class="daa-modal-headline">Firma wechseln</div>

       <div style="height:30px;"></div>

      
            <InputSelect bind:this={components["adminCompany"]}
                fieldName="adminCompany"
                store={$settings}
                data={$fields["adminCompany"]}
                labelClass="daa-label-w140"
                >
            </InputSelect>

        <div class="daa-dialog-buttons-container">
            <ButtonCancel active={true} label="Abbrechen" callback={handleCancel}></ButtonCancel>
            <ButtonOk active={true} label="Ok" callback={handleOk}></ButtonOk>
        </div>

   </div>

</div>

{/if}
