<script>

    import { onMount, onDestroy } from 'svelte';   
    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { validateLocation } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';
    import { getItemText, userText } from '../../stores/userText.js';

    export let fieldName = "none";
    export let labelClass = "daa-label-w400";
    export let store;

    let data = $fields[fieldName];
    let inputFieldZip;
    let inputFieldLocation;
    $:currentZip = store["zipCode"];
    $:currentLocation = store["location"];
    $:showWarning = false;
    $:checkSilently = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {
        currentZip = inputFieldZip.value;
        currentLocation = inputFieldLocation.value;
        saveToStore();
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        checkSilently = !boolSilent;

        let rules = data.validate;
        if (!rules) return "valid";
        let checkChars = validateLocation (inputFieldZip.value,inputFieldLocation.value, rules);
        if(checkChars !=="valid") showWarning = true;
        return checkChars;
    }

    export const getValue = () => {
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentZip = store["zipCode"];
        currentLocation = store["location"];
        inputFieldZip.value = currentZip;
        inputFieldLocation.value = currentLocation;
    };

    const saveToStore = () => {
        store["zipCode"] = currentZip;
        store["location"] = currentLocation;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    onMount(async () => {  
        update();
	});

    onDestroy(() => {
        saveToStore();
	});
   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    
</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for={fieldName}>{getItemText($userText, fieldName, "name", $settings.groupId)}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>

    <div class="daa-input-text-hint-container">

        <div class="daa-input-location">

            <input 
                class="daa-input-text daa-fnt-std" 
                style={(showWarning === false ) ? "": "border: 1px solid red;"}
                type="text" 
                id="zipCode"  
                name="zipCode" 
                bind:this={inputFieldZip}
                on:input={handleInput}> 

            <input 
                class="daa-input-text daa-fnt-std" 
                style={(showWarning === false ) ? "": "border: 1px solid red;"}
                type="text" 
                id={fieldName} 
                name={fieldName} 
                bind:this={inputFieldLocation}
                on:input={handleInput}> 
        </div>

        <HelpButton show={data.useHelp} ref={fieldName}></HelpButton>
       
    </div>
       
</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}

