<script>

    import { helptext }  from "../../stores/helptext.js";  
    import Backdrop from "../backdrop/Backdrop.svelte";
        
    export let show = false;
    export let ref = "none";

    $: isActive = false;
    $: showHtml = $helptext[ref] || `<div>No text</div>`;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
   
    const closeHint = () => {
        isActive = false;
    }

    const openHint = () => {
        console.log("here")
        isActive = true;
    }

</script>

{#if show && isActive} 
    <Backdrop callback={closeHint}></Backdrop>
{/if}

{#if show} 

    <div style="position:relative;">

        <button class="daa-hint-explain" on:click={openHint}>?</button>

        {#if isActive} 
            <div class="daa-help-box">

                <button class="daa-close-modal-btn" on:click={closeHint}>&#x2715;</button>
                {@html showHtml}
                
            </div>
        {/if}

    </div>

{:else}
    <div></div>
{/if}
