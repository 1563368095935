import { writable } from 'svelte/store';

export const groups = writable([
    { id: 1, entityID: "1", entityName: "DAA-Technikum" },
    { id: 2, entityID: "2", entityName: "DAA Deutsche Angestellten-Akademie GmbH" },
    { id: 4, entityID: "4", entityName: "HFH Hamburger Fern-Hochschule" },
    { id: 5, entityID: "5", entityName: "GOB" },
    { id: 6, entityID: "6", entityName: "b+r Bildung und Reisen" },
]);


export const groupsAdress = writable({

    "1_adr": {
        "name": "DAA-Technikum",
        "subline": "",
        "street": "Auf der Union 10",
        "location": "45141 Essen",
        "contact": "z.H. Frau Klahold",
        "eMail": "dozenten@daa-technikum.de"
    },

    "2_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "information@daa.de"
    },

    "4_adr": {
        "name": "HFH Hamburger Fern-Hochschule",
        "subline": "",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "info@hfh-fernstudium.de"
    },

    "5_adr": {
        "name": "GOB",
        "subline": "",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "info(@)gob-service.net"
    },

    "6_adr": {
        "name": "b+r Bildung und Reisen",
        "subline": "",
        "street": "Alter Teichweg 17",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "info@bplusr.de"
    },
    
    "7_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Hamburger Straße 181",
        "location": "22083 Hamburg",
        "contact": "",
        "eMail": "honorar.hh-mv@daa.de"
    },
    "8_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Holstenbrücke 7",
        "location": "24103 Kiel",
        "contact": "",
        "eMail": "honorar.sh@daa.de"
    },
    "9_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Auf dem Rahlande 4-4a",
        "location": "29525 Uelzen",
        "contact": "",
        "eMail": "honorar.nna@daa.de"
    },
    "10_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Theodor-Heuss-Straße 3",
        "location": "38122 Braunschweig",
        "contact": "",
        "eMail": "honorar.mo@daa.de"
    },
    "11_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Herforder Straße 74",
        "location": "33602 Bielefeld",
        "contact": "",
        "eMail": "honorar.nrw@daa.de"
    },
    "12_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Richard-Wagner-Str. 1",
        "location": "67655 Kaiserslautern",
        "contact": "",
        "eMail": "honorar.rsh@daa.de"
    },
    "13_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Marsstraße 42",
        "location": "80335 München",
        "contact": "",
        "eMail": "honorar.by@daa.de"
    },
    "14_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Hackstraße 77",
        "location": "70190 Stuttgart",
        "contact": "",
        "eMail": "honorar.bawue@daa.de"
    },
    "15_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "subline": "Vertraulich / Honorarsache",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "honorar.zdl@daa.de"
    },

});
