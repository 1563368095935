    
export let userObj = {

    "entityID": "",
    "entityName": "",
    "employeeID": "",

    "lastName": "Dummy",
    "firstName": "Max",
    "namePrefix": "",
    "nameAffix": "",
    "title": "",
    "eMail": "dummy@vendor.com",
    "phoneNumber": "",
    
    "countryName":"Deutschland",
    "countryCode":"D",
    "zipCode": "12345",
    "location": "Hintertupfingen",
    "street": "Dummystraße 123",
    "streetNumber": "not used",
    "additionalAddressLine": "",
    
    "nationality": "deutsch",
    "nationalityCode": "D",
    "gender": "männlich",
    "genderCode": "M",
    "birthDate": "",
    "birthName": "mybirthname",
    "birthNamePrefix": "",
    "birthNameAffix": "",
    "birthLocation": "Ganzwoanders",
    "birthCountry": "Deutschland",
    "birthCountryCode": "D",

    "schoolGraduation": "Mittlere Reife",
    "schoolGraduationCode": "3",
    "jobGraduation": "Meister o. Fachschulabschluss", 
    "jobGraduationCode": "3", 

    "IBAN": "DE12345678912345678911",
    "hasIBAN":"",
    "BIC": "",
    "foreignIBAN": "",
    "differentAccountOwner": "",
    
    "taxId": "55060819888",
    "additionalOccupation": "Nein",
    "additionalOccupationCode": "0",
    "ELSTAMOcclusion": "Das weiß ich nicht",
    "ELSTAMOcclusionCode": "-999", 
    "taxClass": "Steuerklasse III",
    "taxClassCode": "3",
    "childrenTaxFreeFactor": "",
    "religiousDenomination": "Römisch-Katholische Kirschensteuer",
    "religiousDenominationCode": "rk",
    "specialIncomeTaxTable": "Normalsteuer",
    "specialIncomeTaxTableCode": "N",

    "officialOrPV": "Ja",
    "officialOrPVCode": "1",
    "totalContributionPrivateHealthInsurance": "",
    "startPensionPayment": "22.04.1999",
    "ESLSTAMBirthDate": "3.04.1988",
    
    "maritalStatus": "geschieden",
    "maritalStatusCode": "G",
    "maritalStatusSince": "19.11.2021",
    
    "healthInsuranceCompany": "AOK PLUS",
    "healthInsuranceOperatingId": "",
    "healthInsuranceCompanyPrivate": "AOK PLUS",
    "healthInsuranceOperatingIdPrivate": "",
    "healthInsurancePrivateCompanyName":"",
    "hasPensionContribution":"",
    "tree": "P",
    "Mini": "Mini",
    "minijobIncomeMonth":"",
    "SSNAvailable":"",
    "SSN":"04200843F651",
    "SSNother":"",
    "SSNotherCode": "0",
    "healthInsuranceType":"",
    "hasChildren":"",
    "childNumber": "",
    "childNumberUnder25":0,
    "Versorgungswerk":"Der Name des Versorgungswerks",
    "VWMitgliednummer": "M123",
    "AddQ":"AddQ",
    "UeLeiterPauschaleMax": "0",
    "UeLeiterHoursPerWeek": "0",
    "BefreiungsbescheidDRV":"",
}