<script> 

    import { modal } from "../../stores/modal";  
    import { endpoints } from '../../stores/endpoints'; 
    import { settings } from '../../stores/settings';   
    import { currentUserId } from '../../stores/user.js';
    import ButtonCancel from "../button/ButtonCancel.svelte";
    import ButtonOk from "../button/ButtonOk.svelte";

    export let data;
    export let callback;

    $:showHintUnsuccess = false;
    $:showHintSuccess = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleCancel = () => {
        showHintSuccess = false;
        showHintUnsuccess = false;
        $modal.current = "none";
    }

    const handleLeaveSuccess = () => {
        showHintSuccess = false;
        showHintUnsuccess = false;
        $modal.current = "none";
        callback("success");
    }

    const handleLeaveUnSuccess = () => {
        showHintSuccess = false;
        showHintUnsuccess = false;
        $modal.current = "none";
        callback("failed");
    }

    const handleOk = () => {
        resetPassword();
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    async function resetPassword () {

        if(!$currentUserId.id || $currentUserId.id < 0) return;

        const id = $currentUserId["id"];
        const response = await fetch($endpoints.EPpath+$endpoints.EPUser+"/"+$currentUserId.id+"/reset", {
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Accept':'application/json',
                'Authorization': 'Bearer '+$settings.token
            },
        });

        const resp = await response.json();

        console.log("reset response is", resp)

        if (resp.status === false) {
            handleUnsuccess();

        } else {
            handleSuccess ()
        }
    }

    const handleUnsuccess = () => {  
        showHintUnsuccess = true;
    }

    const handleSuccess = () => {  
        showHintSuccess = true;
    }


</script>


{#if $modal.current === "resetPWD"}

<div class="daa-backdrop daa-backdrop-dark">

    <div class="daa-dialog-container">

        <div class="daa-modal-headline">Reset Passwort</div>

        <div class="daa-dialog-hint">Möchten Sie das Passwort dieses Users zurücksetzen?</div>
        <div style="height: 20px;"></div>
        <div class="daa-dialog-hint">User Id: {$currentUserId.id}</div>

        <div class="daa-dialog-buttons-container">
            <ButtonCancel active={true} label="Abbrechen" callback={handleCancel}></ButtonCancel>
            <ButtonOk active={true} label="Ok" callback={handleOk}></ButtonOk>
        </div>

         <!-- failed -->
         {#if showHintUnsuccess }

            <div class="daa-login-hint-invalid">Vorgang nicht erfolgreich.</div>
            <div class="daa-dialog-buttons-container">
                <ButtonCancel active={true} label="Abbrechen" callback={handleLeaveUnSuccess}></ButtonCancel>
            </div>
        {/if}

   

       <!-- successful -->
       {#if showHintSuccess }

       <div class="daa-login-hint-success">Reset erfolgreich.</div>
       <div class="daa-dialog-buttons-container">
           <ButtonOk active={true} label="Ok" callback={handleLeaveSuccess}></ButtonOk>
       </div>
    {/if}

    </div>

</div>

{/if}
