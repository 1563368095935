<script>

    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { getItemText, userText } from '../../stores/userText.js';
 
    export let fieldName = "none";
    export let labelClass = "daa-label-w400";
   
    // let text = $fields[fieldName].text;
    let text = getItemText($userText, fieldName, "text", $settings.groupId);
   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    
    export const validate = (bool) => {
        return "valid";
    };

    export const getValue = () => {
        return "";
    };

    export const update = () => {
    };


</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container"></div>
    
    <div class="daa-input-text-hint-container">
        <div>{text}</div>
        <div></div>
       
    </div>
       
</div>
