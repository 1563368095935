<script>

    // @ts-nocheck

    import { onMount, onDestroy } from 'svelte';  
    import { settings } from '../../stores/settings.js';
    import { options } from '../../stores/options.js';   
    import { user } from '../../stores/user.js';   
    import { userObj } from '../../stores/userObj.js';   
    import { validateSelection } from '../helper/validate.js'; 
    import { getItemText, userText } from '../../stores/userText.js';

    export let data;
    export let fieldName = "";
    export let labelClass = "daa-label-w400";
    export let show = "";
    export let value ="";
    export let store;
 
    $:showWarning = false;
    $:selectOptions = $options.countries;
    $:checkSilently = false;
    $:currentIndex = -1;
    $:currentValue = value;
    $:currentFieldText = ""; 
    
    $:selected = data.countryCode;
    let selectField;


    /////////////////////////////////////////////////////////////////////////////////////////////////////////


    const handleChange = (e) => {
        currentValue = selectField.value;
        currentIndex = selectField.selectedIndex;
        let selectedOption = selectField.options[currentIndex]
        currentFieldText = selectedOption.text;
        saveToStore();
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    const optionsFindIndex = (str) => {
        for (let i = 0; i < selectOptions.length; i++) {
            let name = selectOptions[i].name;
            let nationality = selectOptions[i].nationality;
            if (name === str || nationality === str) return i;
        }
        return false;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const findOptionInSource = (str) => {
        // searches in source array, not the real options!
        for (let i = 0; i < selectOptions.length; i++) {
            let name = selectOptions[i].name;
            if (name === str) return [i, selectOptions[i]];
            let nat = selectOptions[i].nationality;
            if (nat === str) return [i, selectOptions[i]];
        }
        return [-1,[]];
    }

    const setSelectedByIndex = (arr) => {
        selectField.options.selectedIndex = arr[0];
        currentIndex = arr[0];
        currentValue = arr[1].value;
        currentFieldText = arr[1].name;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export  function validate (boolSilent) {

        checkSilently = !boolSilent;

        let rules = data.validate;
        if (!rules) return "valid";
        if (rules.length === 0) return "valid";
        let checkSelect = validateSelection (selectField.value, rules); 
        if (checkSelect !=="valid") showWarning = true;
        return checkSelect;

    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const getValue = () => {
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {

        // let findOptionArr = findOptionInSource (store[fieldName]);
        let findOptionArr = findOptionInSource (store[show]);
        setSelectedByIndex(findOptionArr);
        saveToStore();
    };

    const saveToStore = () => {
    
        let obj = {};

        if (show === "countryName") {
            obj["countryCode"] = currentValue;
            obj["countryName"] = currentFieldText;
        };
        if (show === "nationality") {
            obj["nationalityCode"] = currentValue;
            obj["nationality"] = currentFieldText;
        };
        if (show === "birthCountry") {
            obj["birthCountryCode"] = currentValue;
            obj["birthCountry"] = currentFieldText;
        };
        Object.entries(obj).forEach(([key, value]) => {   
            store[key] = value;
        }) 
       
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    onMount(async () => {  
        update();
	});

    onDestroy(() => {
        saveToStore();
	});

</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for="myInput">{getItemText($userText, fieldName, "name", $settings.groupId)}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>
    

    <div class="daa-input-text-hint-container">

        <div class="daa-select">

            {#if show === "countryName"} 
        
                <select bind:this={selectField} 
                    style={(showWarning === false ) ? "": "border: 1px solid red;"}
                    on:change={handleChange}>

                    {#each selectOptions as option}
                        <option value={option.value}>{option.name}</option>
                    {/each} 

                </select>

            {/if}

            {#if show === "nationality"} 
            
                <select bind:this={selectField} 
                style={(showWarning === false ) ? "": "border: 1px solid red;"} 
                on:change={handleChange}>

                    {#each selectOptions as option}
                        <option value={option.value}>{option.nationality}</option>
                    {/each} 

                </select>

            {/if}

            {#if show === "birthCountry"} 
            
                <select bind:this={selectField} 
                style={(showWarning === false ) ? "": "border: 1px solid red;"}
                on:change={handleChange}>

                    {#each selectOptions as option}
                        <option value={option.value}>{option.name}</option>
                    {/each} 

                </select>

            {/if}

        </div>

        {#if data.useHelp} 
            <button class="daa-hint-explain">?</button>
        {:else}
            <div></div>
        {/if}

    </div>

</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}
