<script>

    // @ts-nocheck
    import { endpoints } from '../../stores/endpoints.js';
    import { settings } from '../../stores/settings.js';
    import { minChars } from '../helper/validate.js';
    import ButtonOk from '../button/ButtonOk.svelte';
    import ButtonCancel from '../button/ButtonCancel.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';
    import ModalDisclaimer from '../modal/ModalDisclaimer.svelte';
    import Footer from '../footer/Footer.svelte';

    let inputEmail, inputPwd;
    $:btnActive = false;
    $:showHintUnsuccess = false;
    $:showHintSuccess = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const preCheck = (e) => {
        e.preventDefault();
        showHintUnsuccess = false;
        btnActive = validateEmail (inputEmail.value);
    }
    
    const handleOkButton = () => {
        if (btnActive && !showHintSuccess) {
            sendEMail();
        } else {
            if (showHintSuccess) {
                $settings.view = "login";
            }
        }
    }

    const handleCancelButton = () => {
        reset();
        $settings.view = "login";
    }

    const validateEmail = (str) => {
        let reg = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        let regResult = reg.test(str);
        return regResult;
    }

    ////////////////////////////////// fetch login //////////////////////////////////////////////////////////

    async function sendEMail () {

        const response = await fetch($endpoints.EPpath+$endpoints.EPresetPW, {
            method:"POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "eMail": inputEmail.value
            })
        });

        const resp = await response.json();

        if (resp.status === false) {
            handleUnsuccess();
        } else {
            handleSuccess();
        }
    }

    const reset = () => {
        inputEmail.value = "";
        btnActive = false;
    }

    const handleUnsuccess = () => {
        reset();
        showHintUnsuccess = true;
    }

    const handleSuccess = () => {
        showHintSuccess = true;
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


<div style="height:50px;"></div>

<!-- logos -->
<div class="daa-main-layout">
    <div></div>
    <div class="daa-company-logos">
        <img class="daa-company-logo" style="margin-right:10px;" src="svelte/daa_technikum_logo.png" alt="DAA Technikum Logo">
        <img class="daa-company-logo" src="svelte/daa_gmbh_logo.png" alt="DAA Gmbh Logo">
        <img class="daa-company-logo" src="svelte/hfh_logo.png" alt="HFH_logo">
        <!-- <img class="daa-company-logo" src="svelte/daa_bremen_logo.png" alt="DAA Bremen Logo"> -->
    </div>
    <div></div>
</div>

<div style="height:10px;"></div>

<!-- some text -->
<div class="daa-main-layout">

    <div></div>
    <div>
        <!-- <div class="daa-welcome-headline">Headline here</div> -->
        <div class=" daa-welcome-container">
            <div class="daa-welcome-text">Bitte geben Sie Ihre Email-Adresse ein:</div>
        </div>
    </div>
    <div></div>
 </div>


 <div style="height:20px;"></div>


<!-- login card -->
<div class="daa-card-login">

    <div class="daa-card-login-head">Passwort vergessen</div>
    <div class="daa-card-login-body">

        {#if !showHintSuccess }
    
            <!-- Email -->
            <label class="daa-login-input-label" for="inputEmail">E-Mail-Adresse:</label>
            <input class="daa-login-input" type="text" id="inputEmail" name="inputEmail" autocomplete="off"
                bind:this={inputEmail}
                on:input={preCheck}
                on:change={preCheck}> 
            <div style="height: 12px;"></div>
        {/if}

        {#if showHintUnsuccess }
            <div class="daa-login-hint-invalid">Eingaben ungültig.</div>
        {/if}

        {#if showHintSuccess }
            <div class="daa-login-hint-success" style="padding: 20px;">Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet. Bitte überprüfen Sie Ihr Postfach und ggfs. den Spam-Ordner. Folgen Sie den Anweisungen in der E-Mail, um ein neues Passwort zu erstellen.</div>
        {/if}

        <div style="height:40px;"></div>

        <div class="daa-buttons-row" style="gap:10px;">
            {#if !showHintSuccess }
                <ButtonCancel label="Cancel" active={true} callback={handleCancelButton}></ButtonCancel>
            {/if}
            <ButtonOk label="Ok" active={btnActive} callback={handleOkButton}></ButtonOk>
        </div>
    </div>

   
</div>


<Footer positionFix="true"></Footer>
<ModalImpressum></ModalImpressum>
<ModalDisclaimer></ModalDisclaimer>
