<script>

    import { onMount, onDestroy } from 'svelte';      
    import { settings } from '../../stores/settings.js';
    import { fields } from '../../stores/form.js';
    import { validateEmail } from '../helper/validate.js';
    import { getItemText, userText } from '../../stores/userText.js';

    export let fieldName = "none";
    export let labelClass = "daa-label-w400";
    export let value = "";
    export let store;
   
    let data = $fields[fieldName];
    let inputEmail;
    $:currentValue = value;
    $:showWarning = false;
    $:checkSilently = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {

        saveToStore();
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        checkSilently = boolSilent;

        let rules = data.validate;
        if (!rules) return "valid";
        if (rules.length === 0) return "valid";
        let checkEMail = validateEmail (inputEmail.value, rules); 
        if(checkEMail !=="valid") showWarning = true;
        return checkEMail;
    }

    export const getValue = () => {
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentValue = store[fieldName];
    };

    const saveToStore = () => {
        store[fieldName] = currentValue;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    onMount(async () => {  
	});

    onDestroy(() => {
        saveToStore();
	});
    
</script>


<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for={fieldName}>{getItemText($userText, fieldName, "name", $settings.groupId)}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>
    
    <div class="daa-input-text-hint-container">
        <input 
            bind:this = {inputEmail}
            class="daa-input-text daa-fnt-std" 
            style={(showWarning === false ) ? "": "border: 1px solid red;"}
            type="text" 
            id={fieldName} 
            name={fieldName} 
            bind:value={currentValue}
            on:change={handleInput}> 

        {#if data.useHelp} 
            <button class="daa-hint-explain">?</button>
        {/if}
       
    </div>
       
</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}

