
export function minChars (str, intMin) {

    if (str.length >= intMin) return true;
    return false;
    
}

export function maxChars (str, intMax) {

    if (str.length <= intMax) return true;
    return false;
    
}

export function checkInteger (nr) {
    let result = parseInt(nr);
    if (!result) return false;
    if (typeof(result) !== "number") return false;
    return result;
}

export function onlyNumbers(str) {
    return /^\d+$/.test(str);
}

export function validateChars(str, arr) {
    let result = "valid";

    for (let i = 0; i < arr.length; i++) {
        Object.keys(arr[i]).forEach(function eachKey(keyVal) {
            let key = keyVal; // alerts key
            let value = arr[i][keyVal]; // alerts value

            if (key === "atLeast") {
                if (minChars(str, value) === false) {
                    result = "too little chars";
                    return;
                }
            }

            if (key === "max") {
                if (maxChars(str, value) === false) {
                    result = "too many chars";
                    return;
                }
            }

            if (key === "SSN") {
                let ch = validateSSN(str);
                result = ch;
                return;
            }

            if (key === "IBAN") {
                result = validateIBAN(str); // Call the IBAN validation function
                return; // Exit after checking IBAN
            }
        });
    }

    return result;
}

// IBAN Validation Function
function validateIBAN(str) {
    let convert = str.toUpperCase().replace(/\s/g, ""); // Normalize the IBAN
    const first2 = convert.slice(0, 2); // Get the first two characters (country code)

    // Check if the IBAN belongs to a supported country
    if (first2 !== "DE" && first2 !== "CH" && first2 !== "AT") {
        return "invalid IBAN"; // Invalid country code
    }

    // Define regex patterns for IBAN validation
    const regIBAN_de = /^[A-Z]{2}[A-Z0-9]{20}$/; // Germany
    const regIBAN_ch = /^[A-Z]{2}[A-Z0-9]{19}$/; // Switzerland
    const regIBAN_au = /^[A-Z]{2}[A-Z0-9]{18}$/; // Austria

    // Check IBAN format based on country code
    let regResult;
    if (first2 === "DE") regResult = regIBAN_de.test(convert);
    if (first2 === "CH") regResult = regIBAN_ch.test(convert);
    if (first2 === "AT") regResult = regIBAN_au.test(convert);

    // Validate format
    if (!regResult) {
        return "invalid IBAN format"; // Invalid format
    }

    // Check the length of the IBAN
    const ibanLengths = {
        'DE': 22,
        'CH': 21,
        'AT': 20
    };
    if (convert.length !== ibanLengths[first2]) {
        return "invalid IBAN length"; // Invalid length
    }

    // Perform checksum validation
    if (!ibanChecksumIsValid(convert)) {
        return "invalid IBAN checksum"; // Invalid checksum
    }

    return "valid"; // IBAN is valid
}

// Checksum validation function
function ibanChecksumIsValid(iban) {
    const rearrangedIban = iban.slice(4) + iban.slice(0, 4); // Move first 4 characters to the end

    // Replace letters with their corresponding numeric values
    const numericIban = rearrangedIban.replace(/[A-Z]/g, (match) => match.charCodeAt(0) - 55);

    // Perform modulo 97 operation on the numeric IBAN
    return mod97(numericIban) === 1;
}

// Helper function for modulo 97 operation
function mod97(iban) {
    let remainder = iban;
    let block;

    while (remainder.length > 2) {
        block = remainder.slice(0, 9); // Take first 9 digits
        remainder = (parseInt(block, 10) % 97).toString() + remainder.slice(9); // Modulo 97
    }

    return parseInt(remainder, 10) % 97; // Final modulo 97
}


export function validateSelection (str, arr) {

    let result = "valid";

    for (let i = 0; i<arr.length; i++) {

        Object.keys(arr[i]).forEach(function eachKey(keyVal) { 

          let key = (keyVal); 
          let value = (arr[i][keyVal]);
          
          if (key === "mustSelect") {
            if (!str || str === "") { 
                result = "must select an option";
                return;
            }
          }

        });
    }
   
    return result;
}

export function validateEmail (str, arr) {

    let result = "valid";

    for (let i = 0; i<arr.length; i++) {

        Object.keys(arr[i]).forEach(function eachKey(keyVal) { 

            let key = (keyVal); 
            let value = (arr[i][keyVal]);
            
            if (key === "eMail" && value === "eMailOrEmpty") {
                if (str === "") { 
                    result = "valid";
                    return;
                }
                let reg = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
                let regResult = reg.test(str);
                if ( regResult === false) { 
                    result = "invalid eMail";
                    return;
                }
            }

            if (key === "eMail" && value === "eMail") {
                let reg = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
                let regResult = reg.test(str);
                if ( regResult === false) { 
                    result = "invalid eMail";
                    return;
                }
            }

        });
    }
    return result;
}

export function validateLocation (strZiP,str,arr) {

    let result = "valid";

    for (let i = 0; i<arr.length; i++) {

        Object.keys(arr[i]).forEach(function eachKey(keyVal) { 

          let key = (keyVal); 
          let value = (arr[i][keyVal]);
          let isValid = false;

          if (key === "zip") {
            let reg = /^[0-9]{5}$/;
            let reg4 = /^[0-9]{4}$/;
            let regResult = reg.test(strZiP);
            let regResult2 = reg4.test(strZiP);
            if (regResult === false && regResult2 === false) {
                result = "invalid";
                return;
            }
          }

          if (key === "atLeast") {
            if (minChars(str, value) === false) {
                result = "invalid";
                return;
            }
            if (maxChars(str, 255) === false) {
                result = "invalid";
                return;
            }
          }

        });
    }
   
    return result;

}

export function validateStreet (streetName, streetNumber,arr) {

    let result = "valid";

    for (let i = 0; i<arr.length; i++) {

        Object.keys(arr[i]).forEach(function eachKey(keyVal) { 

          let key = (keyVal); 
          let value = (arr[i][keyVal]);
          
          if (key === "street") {
            if (minChars(streetName, value) === false) {
                result = "too little chars";
                return;
            }
            if (maxChars(streetName, 255) === false) {
                result = "too many chars";
                return;
            }
          }

          if (key === "streetNumber") {
            if (minChars(streetNumber, value) === false) {
                result = "too little chars";
                return;
            }
            if (maxChars(streetNumber, 255) === false) {
                result = "too many chars";
                return;
            }
          }

        });
    }

    return result;
}

export function validateDayMonthYear (day, month, year, arr) {

    let result = {valid:"valid", value:""};

    for (let i = 0; i<arr.length; i++) {

        Object.keys(arr[i]).forEach(function eachKey(keyVal) { 

          let key = (keyVal); 
          let value = (arr[i][keyVal]);
          
          if (key === "date") {

            //year
            if ( !year || onlyNumbers(year) === false || year.length !== 4) {
                result = {valid:"year invalid", value:""};
                return;
            }
            let yr = parseInt(year);
            
            if (yr < 1000 || yr > 9999){
                result = {valid:"year invalid", value:""};
                return;
            }

            //month
            if ( !month || onlyNumbers(month) === false || month.length < 1 || month.length > 2) {
                result = {valid:"month invalid", value:""};
                return;
            }
            let m = parseInt(month);
           
            if (m < 1 || m > 12){
                result = {valid:"month invalid", value:""};
                return;
            }

            // day
            let d = checkInteger(day);
            if (d === false || d<1){
                result = {valid:"day invalid", value:""};
                return;
            }

            let days = [31,28,31,30,31,30,31,31,30,31,30,31];
            let yearInt = parseInt(year);
            if ((yearInt % 4) == 0) days[1] = 29;
            let maxDay = days[m-1]
            if (d > maxDay) {
                result = {valid:"day invalid", value:""};
                return;
            }

            result = {valid:"valid", value: [d,".",m,".",yr].join("")};
          }

        });
    }

    return result;

}

export function dateStringToDate (dateStr) {

    if(!dateStr) return "invalid dateStr";
    let arr = dateStr.split(".");
    if(arr.length !== 3) return "invalid dateStr";

    let day = parseInt(arr[0]);
    let month = parseInt(arr[1]);
    let year = parseInt(arr[2]);

    let d = new Date(year, month-1, day);
    return d;

}

export function validate67 (dateStr) {

    let d = dateStringToDate(dateStr);
    if(!d || d === "invalid dateStr") {
        // console.log("invalid dateStr detected");
        return "invalid";
    }

    let today = new Date();
    let backThen = new Date(); 
    backThen.setFullYear(today.getFullYear() - 67);

    if (d <= backThen) return "b<=";
    if (d > backThen) return "b>";
    return "invalid";
}

export function filterStringToInt (str) {
    let allowed = ["0","1","2","3","4","5","6","7","8","9"];
    let test = str.split("");
    let fin = [];
    for(let i = 0; i < test.length; i++) {
        if (allowed.indexOf(test[i]) >-1) {
            fin.push(test[i])
        }
    }
    let join = fin.join("");
    if (isNaN(parseInt(join))) return 0;
    return parseInt(join);
}

export function filterStringToString (str) {
    let allowed = ["0","1","2","3","4","5","6","7","8","9"];
    let test = str.split("");
    let fin = [];
    for(let i = 0; i < test.length; i++) {
        if (allowed.indexOf(test[i]) >-1) {
            fin.push(test[i])
        }
    }
    let join = fin.join("");
    return join;
}


export function getBirtdateFromSSN (str) {
    let result = checkSSN (str, true);
    return result;
}

export function validateSSN (str) {
    let result = checkSSN (str, false);
    return result;
}


export function checkSSN (str, boolReturnBirthday) {

    let test =  noSpace (str);
    let le = test.length;
    if (le !== 12){
        console.log ("SNN length is not 12",le);
        return "invalid";
    } 
    let char1 = test.substring(0, 1);
    let char2 = test.substring(1, 2);
    if ( isNaN(parseInt(char1)) || isNaN(parseInt(char2))  ){
        console.log ("char 1 or 2 not a number");
        return "invalid";
    }
    
    let lastNameInitial = test.substring(8, 9);
    let regex = /^[a-zA-Z]+$/;
    if (regex.test(lastNameInitial) === false) {
        console.log ("lastNameInitial is not a char");
        return "invalid";

    }
    let serialNumber1 = test.substring(9, 10);
    let serialNumber2 = test.substring(10, 11);
    if ( isNaN(parseInt( serialNumber1)) || isNaN(parseInt(serialNumber2))  ){
        console.log ("serial number 1 or 2 not a number");
        return "invalid";
    }
    let lastDigit = test.substring(11, 12);
    if ( isNaN(parseInt( lastDigit)) ){
        console.log ("last digit is not a number");
        return "invalid";
    }

    let partBirthday = test.substring(2, 8);
    let checkBirthday = filterStringToString(partBirthday)
    if (checkBirthday.length !== 6 ) return "invalid";

    let day = checkBirthday.substring(0, 2);
    let month = checkBirthday.substring(2, 4);
    let year = checkBirthday.substring(4, 6);

    let intDay = parseInt(day);
    
    if (intDay > 31) {
        console.log ("day > 31");
        return "invalid";
    }

    let intMonth = parseInt(month);
    if (intMonth > 12) {
        console.log ("month > 12");
        return "invalid";
    }
    // break if birthday is not needed::
    if (boolReturnBirthday === false) return "valid";

    let intYear = parseInt(year);
    let addToYear = "20";
    if (intYear <= 99) {
        addToYear = "19";
    }
    let arr = [day, ".", month, ".", addToYear+year];
    return arr.join("");
}

export function noSpace (str) {
    let convert = str.replace(/\s/g, "");
    return convert;
}

export function validateTaxId (str) {
    if (str.length !== 11) return "invalid";
    return "valid";
}
