<script>

    import { onMount, onDestroy } from 'svelte';     
    import { fields } from '../../stores/form.js';
    import { validateChars, noSpace } from '../helper/validate.js';
    import { settings } from '../../stores/settings.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';
    import { getItemText, userText } from '../../stores/userText.js';

    export let fieldName = "none";
    export let value = "";
    export let store;
    export let name ="Der Name";
    export let checkBoxLabel= "Das weiß ich nicht."
    export let labelClass = "daa-label-w400";
    export let hideLabel = false;
    export let callbackInput = function(val){};
    export let callbackCheckbox = function(val){};
    
    let data = $fields[fieldName];
    let inputField;
    let checkB;
    $:isChecked = false;
    $:currentValue = value;
    $:tempCurrentValue = value;
    $:showWarning = false;
    $:checkSilently = false;
    $:checkBoxActive = true; // if false, box is dimmed


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleInput = (e) => {
        // console.log("InputTextTree---> ", e.target.value)
        if(currentValue !== "") isChecked = false;
        showWarning = false;
        $settings.showWarningInFooter = false;
        callbackInput(e.target.value);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////


    const handleCheckbox = (e) => {
        isChecked = e.target.checked;
        if (isChecked) {
            tempCurrentValue = currentValue;
            currentValue = "";
        }
        if (!isChecked) {
            currentValue = tempCurrentValue;
        }
        showWarning = false;
        $settings.showWarningInFooter = false;
        callbackCheckbox(isChecked);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

       checkSilently = !boolSilent;

       let rules = data.validate;
        if (!rules) return "valid";
        let checkChars = validateChars (currentValue, rules);
        if(checkChars !=="valid") {
            showWarning = true;
        }
        if(checkB.checked) {
            checkChars = "valid";
            showWarning = false;
        }
        return checkChars;
    };

    export const getValue = () => {
    };

  
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
        currentValue = store[fieldName];
        tempCurrentValue = store[fieldName];
        let deleteSpaces = noSpace(currentValue);
        if (currentValue === "unknown" || currentValue === "nicht bekannt" || deleteSpaces === "") {
            isChecked = true;
            currentValue = "";
            tempCurrentValue = "";
        }
    };

    export const saveToStore = () => {
        store[fieldName] = currentValue;
        if (isChecked) {
            store[fieldName] = "unknown";
        }
    }

     /////////////////////////////////////////////////////////////////////////////////////////////////////////

     onMount(async () => {
        update();
    });

    onDestroy(() => {
        saveToStore();
    });


    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
</script>


<div class="daa-tree-subtype {labelClass}">
    <div></div>
    <div class="daa-tree-subtype-content">

        {#if hideLabel}
        <div></div>
        {:else}
        <div class="daa-tree-subtype-label">{getItemText($userText, fieldName, "name", $settings.groupId)}</div>
        {/if}

        <div class="daa-input-text-hint-container">

            <div class="daa-tree-input-text-unknown-container">
                <input 
                    class="daa-input-text daa-fnt-std" 
                    style={(showWarning === false ) ? "": "border: 1px solid red;"}
                    type="text" 
                    id={fieldName} 
                    name={fieldName} 
                    bind:this={inputField}
                    bind:value={currentValue}
                    on:input={handleInput}> 

                    <div class="daa-checkbox-unknown-container">
                        <input type="checkbox" bind:this={checkB} 
                        bind:checked={isChecked} 
                        on:change={handleCheckbox}
                        id="{fieldName}_unknown" 
                        name="{fieldName}_unknown" 
                        value="{fieldName}_unknown">
                    <label class="daa-checkbox-unknown-label" for="{fieldName}_unknown">{checkBoxLabel}</label>
                </div>
            </div>
    
            <HelpButton show={data.useHelp} ref={data.name}></HelpButton>
           
        </div>

    </div>  
    
    
    
       
</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid ">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}

