<script>

    // @ts-nocheck
    import { onMount } from 'svelte';  
    import { settings } from '../../stores/settings.js';
    import { userObj } from '../../stores/userObj.js';
    import { sendDocs } from '../../stores/sendDocs.js';
    import { groupsAdress } from '../../stores/groups.js';
    import SendDocsItem from './SendDocsItem.svelte';

    export let store;

    $:currentAdress = $groupsAdress[$settings.groupId+"_adr"];

    const collectItems = () => {

        let items = []
        if (store.hasChildren ==="yes") items.push("children");
        if (store.healthInsuranceType === "privat versichert") items.push("privateHealthInsurance");
        if (store.tree === "P_D_A") items.push("Immatrikulationsbescheinigung");
        if (store.tree === "P_A_A_A") items.push("Gewährleistungserstreckungsbescheid");
        if (store.tree === "P_A_B_A") items.push("Gewährleistungserstreckungsbescheid");
        if (store.tree === "P_B_A_B") items.push("Versorgungswerk"); 
        if (store.tree === "P_B_B_B") items.push("Versorgungswerk");
        if (store.tree === "P_B_C_B") items.push("Versorgungswerk");
        if (store.tree === "P_B_D_B") items.push("Versorgungswerk");
        if (store.tree === "P_C_B") items.push("Versorgungswerk");
        if (store.tree === "P_B_A_A") items.push("Rentenbescheid");
        if (store.tree === "P_B_B_A") items.push("Rentenbescheid"); 
        if (store.tree === "P_B_C_A") items.push("Rentenbescheid"); 
        if (store.tree === "P_B_D_A") items.push("Rentenbescheid"); 
        
        return items;
    }

    $:docItems = collectItems();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const startValidation = () => {
    }
   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const validatePanel = () => {
    };

    export const update = () => {
       
    }

    onMount(async () => {
        update();
	});

    

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


{#if docItems.length > 0}

<div class="daa-main-layout">
    <div></div>
    <div class="daa-send-doc-hint">Und hier der Überblick über die Dokumente, die Sie uns bitte noch zusenden:</div>
    <div></div>
</div>


<div class="daa-send-doc-main-layout">
    <div></div>
    <div class="daa-send-doc-frame">
       
        <div class="daa-send-doc-container">
            {#each docItems as docItem}
                <SendDocsItem key={docItem}></SendDocsItem>
	        {/each}
        </div>

        <div class="daa-send-doc-adress-container">

            <div class="daa-send-doc-adress-grid-2box">
                <div class="daa-send-doc-adress-head">Bitte senden an:</div>
                <div class="daa-send-doc-adress-box">
                    <div class="daa-send-doc-adress-text">{currentAdress.name}</div>
                    {#if currentAdress.subline !== ""}
                    <div class="daa-send-doc-adress-text">{currentAdress.subline}</div>
                    {/if}
                    {#if currentAdress.contact !== ""}
                    <div class="daa-send-doc-adress-text">{currentAdress.contact}</div>
                    {/if}
                    <div class="daa-send-doc-adress-text">{currentAdress.street}</div>
                    <div class="daa-send-doc-adress-text">{currentAdress.location}</div>
                   
                </div>
            </div>

            <div class="daa-send-doc-adress-grid-2box">
                <div class="daa-send-doc-adress-head">Oder per E-Mail:</div>
                <div class="daa-send-doc-adress-box">
                    <div class="daa-send-doc-adress-text">{currentAdress.eMail}</div>
                </div>
            </div>
        </div>
    </div>
    <div></div>
 </div>


 {/if}


