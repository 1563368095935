import { writable } from 'svelte/store';

export const options = writable({

"title": [
    {"value":"", "name": ""}, 
    {"value":"Prof. Dr.", "name": "Prof. Dr."}, 
    {"value":"Dr.", "name": "Dr."} 
],

"nameAffix": [
    {"value":"", "name": ""}, 
    {"value":"Bar", "name": "Bar"},
    {"value":"Baron", "name": "Baron"},
    {"value":"Baroness", "name": "Baroness"},
    {"value":"Baronesse", "name": "Baronesse"},
    {"value":"Baronin", "name": "Baronin"},
    {"value":"Brand", "name": "Brand"},
    {"value":"Burggraf", "name": "Burggraf"},
    {"value":"Burggräfin", "name": "Burggräfin"},
    {"value":"Condesa", "name": "Condesa"},
    {"value":"Earl", "name": "Earl"},
    {"value":"Edle", "name": "Edle"},
    {"value":"Edler", "name": "Edler"},
    {"value":"Erbgraf", "name": "Erbgraf"},
    {"value":"Erbgräfin", "name": "Erbgräfin"},
    {"value":"Erbprinz", "name": "Erbprinz"},
    {"value":"Erbprinzessin", "name": "Erbprinzessin"},
    {"value":"Ffr", "name": "Ffr"},
    {"value":"Freifr", "name": "Freifr"},
    {"value":"Freifräulein", "name": "Freifräulein"},
    {"value":"Freifrau", "name": "Freifrau"},
    {"value":"Freih", "name": "Freih"},
    {"value":"Freiherr", "name": "Freiherr"},
    {"value":"Freiin", "name": "Freiin"},
    {"value":"Frf", "name": "Frf"},
    {"value":"Frf.", "name": "Frf."},
    {"value":"Frfr", "name": "Frfr"},
    {"value":"Frfr.", "name": "Frfr."},
    {"value":"Frh", "name": "Frh"},
    {"value":"Frh.", "name": "Frh."},
    {"value":"Frhr", "name": "Frhr"},
    {"value":"Frhr.", "name": "Frhr."},
    {"value":"Fst", "name": "Fst"},
    {"value":"Fst.", "name": "Fst."},
    {"value":"Fstn", "name": "Fstn"},
    {"value":"Fstn.", "name": "Fstn."},
    {"value":"Fürst", "name": "Fürst"},
    {"value":"Fürstin", "name": "Fürstin"},
    {"value":"Gr", "name": "Gr"},
    {"value":"Graf", "name": "Graf"},
    {"value":"Gräfin", "name": "Gräfin"},
    {"value":"Grf", "name": "Grf"},
    {"value":"Grfn", "name": "Grfn"},
    {"value":"Grossherzog", "name": "Grossherzog"},
    {"value":"Großherzog", "name": "Großherzog"},
    {"value":"Grossherzogin", "name": "Grossherzogin"},
    {"value":"Großherzogin", "name": "Großherzogin"},
    {"value":"Herzog", "name": "Herzog"},
    {"value":"Herzogin", "name": "Herzogin"},
    {"value":"Jhr", "name": "Jhr"},
    {"value":"Jhr.", "name": "Jhr."},
    {"value":"Jonkheer", "name": "Jonkheer"},
    {"value":"Junker", "name": "Junker"},
    {"value":"Landgraf", "name": "Landgraf"},
    {"value":"Landgräfin", "name": "Landgräfin"},
    {"value":"Markgraf", "name": "Markgraf"},
    {"value":"Markgräfin", "name": "Markgräfin"},
    {"value":"Marques", "name": "Marques"},
    {"value":"Marquis", "name": "Marquis"},
    {"value":"Marschall", "name": "Marschall"},
    {"value":"Ostoja", "name": "Ostoja"},
    {"value":"Prinz", "name": "Prinz"},
    {"value":"Prinzessin", "name": "Prinzessin"},
    {"value":"Przin", "name": "Przin"},
    {"value":"Rabe", "name": "Rabe"},
    {"value":"Reichsgraf", "name": "Reichsgraf"},
    {"value":"Reichsgräfin", "name": "Reichsgräfin"},
    {"value":"Ritter", "name": "Ritter"},
    {"value":"Rr", "name": "Rr"},
    {"value":"Truchsess", "name": "Truchsess"},
    {"value":"Truchseß", "name": "Truchseß"}
],

"namePrefix": [

    {"value":"", "name": ""},   				
    {"value":"a", "name": "a"},
    {"value":"aan de", "name": "aan de"},
    {"value":"aan den", "name": "aan den"},
    {"value":"al", "name": "al"},
    {"value":"am", "name": "am"},
    {"value":"an", "name": "an"},
    {"value":"an der", "name": "an der"},
    {"value":"auf", "name": "auf"},
    {"value":"auf dem", "name": "auf dem"},
    {"value":"auf der", "name": "auf der"},
    {"value":"auf m", "name": "auf m"},
    {"value":"aufm", "name": "aufm"},
    {"value":"auff m", "name": "auff m"},
    {"value":"aus", "name": "aus"},
    {"value":"aus dem", "name": "aus dem"},
    {"value":"aus den", "name": "aus den"},
    {"value":"aus der", "name": "aus der"},
    {"value":"b", "name": "b"},
    {"value":"be", "name": "be"},
    {"value":"bei", "name": "bei"},
    {"value":"bei der", "name": "bei der"},
    {"value":"beim", "name": "beim"},
    {"value":"ben", "name": "ben"},
    {"value":"bey", "name": "bey"},
    {"value":"bey der", "name": "bey der"},
    {"value":"che", "name": "che"},
    {"value":"cid", "name": "cid"},
    {"value":"d", "name": "d"},
    {"value":"d.", "name": "d."},
    {"value":"d'", "name": "d'"},
    {"value":"da", "name": "da"},
    {"value":"da costa", "name": "da costa"},
    {"value":"da las", "name": "da las"},
    {"value":"da silva", "name": "da silva"},
    {"value":"dal", "name": "dal"},
    {"value":"dall", "name": "dall"},
    {"value":"dall'", "name": "dall'"},
    {"value":"dalla", "name": "dalla"},
    {"value":"dalle", "name": "dalle"},
    {"value":"dallo", "name": "dallo"},
    {"value":"das", "name": "das"},
    {"value":"de", "name": "de"},
    {"value":"degli", "name": "degli"},
    {"value":"dei", "name": "dei"},
    {"value":"den", "name": "den"},
    {"value":"de l '", "name": "de l '"},
    {"value":"de la", "name": "de la"},
    {"value":"de las", "name": "de las"},
    {"value":"de le", "name": "de le"},
    {"value":"de los", "name": "de los"},
    {"value":"del", "name": "del"},
    {"value":"del coz", "name": "del coz"},
    {"value":"deli", "name": "deli"},
    {"value":"dell", "name": "dell"},
    {"value":"dell'", "name": "dell'"},
    {"value":"della", "name": "della"},
    {"value":"delle", "name": "delle"},
    {"value":"delli", "name": "delli"},
    {"value":"dello", "name": "dello"},
    {"value":"der", "name": "der"},
    {"value":"des", "name": "des"},
    {"value":"di", "name": "di"},
    {"value":"dit", "name": "dit"},
    {"value":"do", "name": "do"},
    {"value":"do ceu", "name": "do ceu"},
    {"value":"don", "name": "don"},
    {"value":"don le", "name": "don le"},
    {"value":"dos", "name": "dos"},
    {"value":"dos santos", "name": "dos santos"},
    {"value":"du", "name": "du"},
    {"value":"dy", "name": "dy"},
    {"value":"el", "name": "el"},
    {"value":"g", "name": "g"},
    {"value":"gen", "name": "gen"},
    {"value":"gil", "name": "gil"},
    {"value":"gli", "name": "gli"},
    {"value":"grosse", "name": "grosse"},
    {"value":"große", "name": "große"},
    {"value":"i", "name": "i"},
    {"value":"im", "name": "im"},
    {"value":"in", "name": "in"},
    {"value":"in de", "name": "in de"},
    {"value":"in den", "name": "in den"},
    {"value":"in der", "name": "in der"},
    {"value":"in het", "name": "in het"},
    {"value":"in't", "name": "in't"},
    {"value":"kl", "name": "kl"},
    {"value":"kleine", "name": "kleine"},
    {"value":"l", "name": "l"},
    {"value":"l.", "name": "l."},
    {"value":"l'", "name": "l'"},
    {"value":"la", "name": "la"},
    {"value":"le", "name": "le"},
    {"value":"lee", "name": "lee"},
    {"value":"li", "name": "li"},
    {"value":"lo", "name": "lo"},
    {"value":"m", "name": "m"},
    {"value":"mc", "name": "mc"},
    {"value":"mac", "name": "mac"},
    {"value":"n", "name": "n"},
    {"value":"o", "name": "o"},
    {"value":"o'", "name": "o'"},
    {"value":"op", "name": "op"},
    {"value":"op de", "name": "op de"},
    {"value":"op den", "name": "op den"},
    {"value":"op gen", "name": "op gen"},
    {"value":"op het", "name": "op het"},
    {"value":"op te", "name": "op te"},
    {"value":"op ten", "name": "op ten"},
    {"value":"oude", "name": "oude"},
    {"value":"pla", "name": "pla"},
    {"value":"pro", "name": "pro"},
    {"value":"s", "name": "s"},
    {"value":"st.", "name": "st."},
    {"value":"t", "name": "t"},
    {"value":"te", "name": "te"},
    {"value":"ten", "name": "ten"},
    {"value":"ter", "name": "ter"},
    {"value":"thi", "name": "thi"},
    {"value":"tho", "name": "tho"},
    {"value":"thom", "name": "thom"},
    {"value":"thor", "name": "thor"},
    {"value":"thum", "name": "thum"},
    {"value":"to", "name": "to"},
    {"value":"tom", "name": "tom"},
    {"value":"tor", "name": "tor"},
    {"value":"tu", "name": "tu"},
    {"value":"tum", "name": "tum"},
    {"value":"unten", "name": "unten"},
    {"value":"unter", "name": "unter"},
    {"value":"unterm", "name": "unterm"},
    {"value":"v.", "name": "v."},
    {"value":"v. d.", "name": "v. d."},
    {"value":"v. dem", "name": "v. dem"},
    {"value":"v. den", "name": "v. den"},
    {"value":"v. der", "name": "v. der"},
    {"value":"v.d.", "name": "v.d."},
    {"value":"v.dem", "name": "v.dem"},
    {"value":"v.den", "name": "v.den"},
    {"value":"v.der", "name": "v.der"},
    {"value":"van", "name": "van"},
    {"value":"van de", "name": "van de"},
    {"value":"van dem", "name": "van dem"},
    {"value":"van den", "name": "van den"},
    {"value":"van der", "name": "van der"},
    {"value":"vande", "name": "vande"},
    {"value":"vandem", "name": "vandem"},
    {"value":"vanden", "name": "vanden"},
    {"value":"vander", "name": "vander"},
    {"value":"van gen", "name": "van gen"},
    {"value":"van het", "name": "van het"},
    {"value":"van t", "name": "van t"},
    {"value":"ven", "name": "ven"},
    {"value":"ven der", "name": "ven der"},
    {"value":"ver", "name": "ver"},
    {"value":"vo", "name": "vo"},
    {"value":"vom", "name": "vom"},
    {"value":"vom und zu", "name": "vom und zu"},
    {"value":"von", "name": "von"},
    {"value":"von und zu", "name": "von und zu"},
    {"value":"von und zu der", "name": "von und zu der"},
    {"value":"von und zur", "name": "von und zur"},
    {"value":"von de", "name": "von de"},
    {"value":"von dem", "name": "von dem"},
    {"value":"von den", "name": "von den"},
    {"value":"von der", "name": "von der"},
    {"value":"von la", "name": "von la"},
    {"value":"von zu", "name": "von zu"},
    {"value":"von zum", "name": "von zum"},
    {"value":"von zur", "name": "von zur"},
    {"value":"vonde", "name": "vonde"},
    {"value":"vonden", "name": "vonden"},
    {"value":"vondem", "name": "vondem"},
    {"value":"vonder", "name": "vonder"},
    {"value":"von einem", "name": "von einem"},
    {"value":"von mast", "name": "von mast"},
    {"value":"vor", "name": "vor"},
    {"value":"vor dem", "name": "vor dem"},
    {"value":"vor den", "name": "vor den"},
    {"value":"vor der", "name": "vor der"},
    {"value":"vorm", "name": "vorm"},
    {"value":"vorn", "name": "vorn"},
    {"value":"y", "name": "y"},
    {"value":"y del", "name": "y del"},
    {"value":"zu", "name": "zu"},
    {"value":"zum", "name": "zum"},
    {"value":"zur", "name": "zur"}
],

"gender": [
    {"value": "","name":""},
    {"value":"M", "name":"männlich"},
    {"value":"W", "name": "weiblich"},
    {"value":"D", "name":"divers"},
    {"value":"X", "name":"unbestimmt"}
],

"bankTransfer": [

    ["SEPA-Überweisung", "1"],
    ["Barzahlung", "2"],
],

"countries":[
    {"value":"D", "name":"Deutschland", "DEÜV":"000", "nationality":"deutsch"},
    {"value":"996", "name":"unbekanntes Ausland", "DEÜV":"996", "nationality":"unbekannt"},
    {"value":"997", "name":"staatenlos", "DEÜV":"997", "nationality":"staatenlos"},
    {"value":"998", "name":"ungeklärt", "DEÜV":"998", "nationality":"ungeklärt"},
    {"value":"999", "name":"ohne Angabe", "DEÜV":"999", "nationality":"ohne Angabe"},								
    {"value":"AFG", "name":"Afghanistan", "DEÜV":"423", "nationality":"afghanisch"},
    {"value":"ET", "name":"Ägypten", "DEÜV":"287", "nationality":"ägyptisch"},
    {"value":"GB", "name":"Akrotiri und Dhekelia", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"AX", "name":"Åland", "DEÜV":"128", "nationality":"finnisch"},
    {"value":"AL", "name":"Albanien", "DEÜV":"121", "nationality":"albanisch"},
    {"value":"DZ", "name":"Algerien", "DEÜV":"221", "nationality":"algerisch"},
    {"value":"AJ", "name":"Amerikanische Jungferninseln", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"AS", "name":"Amerikanisch-Samoa", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"AND", "name":"Andorra", "DEÜV":"123", "nationality":"andorranisch"},
    {"value":"AGO", "name":"Angola", "DEÜV":"223", "nationality":"angolanisch"},
    {"value":"ANG", "name":"Anguilla", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"ANT", "name":"Antigua und Barbuda", "DEÜV":"320", "nationality":"antiguanisch"},
    {"value":"AQU", "name":"Äquatorialguinea", "DEÜV":"274", "nationality":"äquatorialguineisch"},
    {"value":"RA", "name":"Argentinien", "DEÜV":"323", "nationality":"argentinisch"},
    {"value":"AQ", "name":"Argentinische Antarktis", "DEÜV":"323", "nationality":"argentinisch"},
    {"value":"ARM", "name":"Armenien", "DEÜV":"422", "nationality":"armenisch"},
    {"value":"AW", "name":"Aruba", "DEÜV":"148", "nationality":"niederländisch"},
    {"value":"HEL", "name":"Ascension", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"ASE", "name":"Aserbaidschan", "DEÜV":"425", "nationality":"aserbaidschanisch"},
    {"value":"AU", "name":"Ashmore- und Cartierinseln", "DEÜV":"523", "nationality":"australisch"},
    {"value":"ETH", "name":"Äthiopien", "DEÜV":"225", "nationality":"äthiopisch"},
    {"value":"AUS", "name":"Australien", "DEÜV":"523", "nationality":"australisch"},
    {"value":"AQ", "name":"Australisches Antarktis-Territorium", "DEÜV":"523", "nationality":"australisch"},
    {"value":"BS", "name":"Bahamas", "DEÜV":"324", "nationality":"bahamaisch"},
    {"value":"BRN", "name":"Bahrain", "DEÜV":"424", "nationality":"bahrainisch"},
    {"value":"BD", "name":"Bangladesch", "DEÜV":"460", "nationality":"bangladeschisch"},
    {"value":"BDS", "name":"Barbados", "DEÜV":"322", "nationality":"barbadisch"},
    {"value":"BY", "name":"Belarus", "DEÜV":"169", "nationality":"belarussisch"},
    {"value":"B", "name":"Belgien", "DEÜV":"124", "nationality":"belgisch"},
    {"value":"BH", "name":"Belize", "DEÜV":"330", "nationality":"belizisch"},
    {"value":"DY", "name":"Benin", "DEÜV":"229", "nationality":"beninisch"},
    {"value":"BER", "name":"Bermuda", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"BHT", "name":"Bhutan", "DEÜV":"426", "nationality":"bhutanisch"},
    {"value":"BOL", "name":"Bolivien", "DEÜV":"326", "nationality":"bolivianisch"},
    {"value":"BQ", "name":"Bonaire, Saba, St. Eustatius", "DEÜV":"148", "nationality":"niederländisch"},
    {"value":"BIH", "name":"Bosnien und Herzegowina", "DEÜV":"122", "nationality":"bosnisch-herzegowinisch"},
    {"value":"RB", "name":"Botsuana", "DEÜV":"227", "nationality":"botsuanisch"},
    {"value":"BV", "name":"Bouvetinsel", "DEÜV":"149", "nationality":"norwegisch"},
    {"value":"BR", "name":"Brasilien", "DEÜV":"327", "nationality":"brasilianisch"},
    {"value":"BJ", "name":"Britische Jungferninseln", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"AQ", "name":"Britisches Antarktis-Territorium", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"IO", "name":"Britisches Territorium im Indischen Ozean", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"BRU", "name":"Brunei Darussalam", "DEÜV":"429", "nationality":"bruneiisch"},
    {"value":"BG", "name":"Bulgarien", "DEÜV":"125", "nationality":"bulgarisch"},
    {"value":"HV", "name":"Burkina Faso", "DEÜV":"258", "nationality":"burkinisch"},
    {"value":"RU", "name":"Burundi", "DEÜV":"291", "nationality":"burundisch"},
    {"value":"CV", "name":"Cabo Verde", "DEÜV":"242", "nationality":"cabo-verdisch"},
    {"value":"E", "name":"Ceuta", "DEÜV":"161", "nationality":"spanisch"},
    {"value":"RCH", "name":"Chile", "DEÜV":"332", "nationality":"chilenisch"},
    {"value":"AQ", "name":"Chilenische Antarktis", "DEÜV":"332", "nationality":"chilenisch"},
    {"value":"TJ", "name":"China", "DEÜV":"479", "nationality":"chinesisch"},
    {"value":"CP", "name":"Clipperton", "DEÜV":"129", "nationality":"französisch"},
    {"value":"COI", "name":"Cookinseln", "DEÜV":"536", "nationality":"neuseeländisch"},
    {"value":"CR", "name":"Costa Rica", "DEÜV":"334", "nationality":"costa-ricanisch"},
    {"value":"CI", "name":"Côte d’Ivoire", "DEÜV":"231", "nationality":"ivorisch"},
    {"value":"CW", "name":"Curaçao", "DEÜV":"148", "nationality":"niederländisch"},
    {"value":"DK", "name":"Dänemark", "DEÜV":"126", "nationality":"dänisch"},

    {"value":"WD", "name":"Dominica", "DEÜV":"333", "nationality":"dominicanisch"},
    {"value":"DOM", "name":"Dominikanische Republik", "DEÜV":"335", "nationality":"dominikanisch"},
    {"value":"DSC", "name":"Dschibuti", "DEÜV":"230", "nationality":"dschibutisch"},
    {"value":"EC", "name":"Ecuador", "DEÜV":"336", "nationality":"ecuadorianisch"},
    {"value":"ES", "name":"El Salvador", "DEÜV":"337", "nationality":"salvadorianisch"},
    {"value":"ERI", "name":"Eritrea", "DEÜV":"224", "nationality":"eritreisch"},
    {"value":"EST", "name":"Estland", "DEÜV":"127", "nationality":"estnisch"},
    {"value":"SWZ", "name":"Eswatini", "DEÜV":"281", "nationality":"eswatinisch"},
    {"value":"FAL", "name":"Falklandinseln", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"FR", "name":"Färöer", "DEÜV":"126", "nationality":"dänisch"},
    {"value":"FJI", "name":"Fidschi", "DEÜV":"526", "nationality":"fidschianisch"},
    {"value":"FIN", "name":"Finnland", "DEÜV":"128", "nationality":"finnisch"},
    {"value":"F", "name":"Frankreich", "DEÜV":"129", "nationality":"französisch"},
    {"value":"TF", "name":"Französische Süd- und Antarktisgebiete", "DEÜV":"129", "nationality":"französisch"},
    {"value":"FG", "name":"Französisch-Guayana", "DEÜV":"129", "nationality":"französisch"},
    {"value":"FP", "name":"Französisch-Polynesien", "DEÜV":"129", "nationality":"französisch"},
    {"value":"GAB", "name":"Gabun", "DEÜV":"236", "nationality":"gabunisch"},
    {"value":"WAG", "name":"Gambia", "DEÜV":"237", "nationality":"gambisch"},
    {"value":"GEO", "name":"Georgien", "DEÜV":"430", "nationality":"georgisch"},
    {"value":"GH", "name":"Ghana", "DEÜV":"238", "nationality":"ghanaisch"},
    {"value":"GIB", "name":"Gibraltar", "DEÜV":"168", "nationality":"britisch"},
    {"value":"WG", "name":"Grenada", "DEÜV":"340", "nationality":"grenadisch"},
    {"value":"GR", "name":"Griechenland", "DEÜV":"134", "nationality":"griechisch"},
    {"value":"GRO", "name":"Grönland", "DEÜV":"126", "nationality":"dänisch"},
    {"value":"GUA", "name":"Guadeloupe", "DEÜV":"129", "nationality":"französisch"},
    {"value":"GUM", "name":"Guam", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"GCA", "name":"Guatemala", "DEÜV":"345", "nationality":"guatemaltekisch"},
    {"value":"GG", "name":"Guernsey", "DEÜV":"168", "nationality":"britisch"},
    {"value":"RG", "name":"Guinea", "DEÜV":"261", "nationality":"guineisch"},
    {"value":"GUB", "name":"Guinea-Bissau", "DEÜV":"259", "nationality":"guinea-bissauisch"},
    {"value":"GUY", "name":"Guyana", "DEÜV":"328", "nationality":"guyanisch"},
    {"value":"RH", "name":"Haiti", "DEÜV":"346", "nationality":"haitianisch"},
    {"value":"HM", "name":"Heard und McDonaldinseln", "DEÜV":"523", "nationality":"australisch"},
    {"value":"HCA", "name":"Honduras", "DEÜV":"347", "nationality":"honduranisch"},
    {"value":"HKG", "name":"Hongkong", "DEÜV":"411", "nationality":"chinesisch (Hongkong)"},
    {"value":"IND", "name":"Indien", "DEÜV":"436", "nationality":"indisch"},
    {"value":"RI", "name":"Indonesien", "DEÜV":"437", "nationality":"indonesisch"},
    {"value":"MAN", "name":"Insel Man", "DEÜV":"168", "nationality":"britisch"},
    {"value":"IRQ", "name":"Irak", "DEÜV":"438", "nationality":"irakisch"},
    {"value":"IR", "name":"Iran", "DEÜV":"439", "nationality":"iranisch"},
    {"value":"IRL", "name":"Irland", "DEÜV":"135", "nationality":"irisch"},
    {"value":"IS", "name":"Island", "DEÜV":"136", "nationality":"isländisch"},
    {"value":"IL", "name":"Israel", "DEÜV":"441", "nationality":"israelisch"},
    {"value":"I", "name":"Italien", "DEÜV":"137", "nationality":"italienisch"},
    {"value":"JA", "name":"Jamaika", "DEÜV":"355", "nationality":"jamaikanisch"},
    {"value":"J", "name":"Japan", "DEÜV":"442", "nationality":"japanisch"},
    {"value":"YEM", "name":"Jemen", "DEÜV":"421", "nationality":"jemenitisch"},
    {"value":"JE", "name":"Jersey", "DEÜV":"168", "nationality":"britisch"},
    {"value":"JOR", "name":"Jordanien", "DEÜV":"445", "nationality":"jordanisch"},
    {"value":"KAI", "name":"Kaimaninseln", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"K", "name":"Kambodscha", "DEÜV":"446", "nationality":"kambodschanisch"},
    {"value":"CAM", "name":"Kamerun", "DEÜV":"262", "nationality":"kamerunisch"},
    {"value":"CDN", "name":"Kanada", "DEÜV":"348", "nationality":"kanadisch"},
    {"value":"KAS", "name":"Kasachstan", "DEÜV":"444", "nationality":"kasachisch"},
    {"value":"QAT", "name":"Katar", "DEÜV":"447", "nationality":"katarisch"},
    {"value":"EAK", "name":"Kenia", "DEÜV":"243", "nationality":"kenianisch"},
    {"value":"KIS", "name":"Kirgisistan", "DEÜV":"450", "nationality":"kirgisisch"},
    {"value":"KIB", "name":"Kiribati", "DEÜV":"530", "nationality":"kiribatisch"},
    {"value":"UM", "name":"Kleinere Amerikanische Überseeinseln", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"CC", "name":"Kokosinseln", "DEÜV":"523", "nationality":"australisch"},
    {"value":"CO", "name":"Kolumbien", "DEÜV":"349", "nationality":"kolumbianisch"},
    {"value":"KOM", "name":"Komoren", "DEÜV":"244", "nationality":"komorisch"},
    {"value":"RCB", "name":"Kongo(-Brazzaville), Republik", "DEÜV":"245", "nationality":"kongolesisch"},
    {"value":"ZRE", "name":"Kongo, Demokratische Republik (ehem. Zaire)", "DEÜV":"246", "nationality":"der Demokratischen Republik Kongo"},
    {"value":"AU", "name":"Korallenmeerinseln", "DEÜV":"523", "nationality":"australisch"},
    {"value":"KOR", "name":"Korea, Demokratische Volksrepublik (Nordkorea)", "DEÜV":"434", "nationality":"der Demokratischen Volksrepublik Korea"},
    {"value":"ROK", "name":"Korea, Republik (Südkorea)", "DEÜV":"467", "nationality":"der Republik Korea"},
    {"value":"KOS", "name":"Kosovo", "DEÜV":"150", "nationality":"kosovarisch"},
    {"value":"HR", "name":"Kroatien", "DEÜV":"130", "nationality":"kroatisch"},
    {"value":"C", "name":"Kuba", "DEÜV":"351", "nationality":"kubanisch"},
    {"value":"KWT", "name":"Kuwait", "DEÜV":"448", "nationality":"kuwaitisch"},
    {"value":"LAO", "name":"Laos", "DEÜV":"449", "nationality":"laotisch"},
    {"value":"LS", "name":"Lesotho", "DEÜV":"226", "nationality":"lesothisch"},
    {"value":"LV", "name":"Lettland", "DEÜV":"139", "nationality":"lettisch"},
    {"value":"RL", "name":"Libanon", "DEÜV":"451", "nationality":"libanesisch"},
    {"value":"LB", "name":"Liberia", "DEÜV":"247", "nationality":"liberianisch"},
    {"value":"LAR", "name":"Libyen", "DEÜV":"248", "nationality":"libysch"},
    {"value":"FL", "name":"Liechtenstein", "DEÜV":"141", "nationality":"liechtensteinisch"},
    {"value":"LT", "name":"Litauen", "DEÜV":"142", "nationality":"litauisch"},
    {"value":"L", "name":"Luxemburg", "DEÜV":"143", "nationality":"luxemburgisch"},
    {"value":"MAC", "name":"Macau", "DEÜV":"412", "nationality":"chinesisch (Macau)"},
    {"value":"RM", "name":"Madagaskar", "DEÜV":"249", "nationality":"madagassisch"},
    {"value":"MW", "name":"Malawi", "DEÜV":"256", "nationality":"malawisch"},
    {"value":"MAL", "name":"Malaysia", "DEÜV":"482", "nationality":"malaysisch"},
    {"value":"BIO", "name":"Malediven", "DEÜV":"454", "nationality":"maledivisch"},
    {"value":"RMM", "name":"Mali", "DEÜV":"251", "nationality":"malisch"},
    {"value":"M", "name":"Malta", "DEÜV":"145", "nationality":"maltesisch"},
    {"value":"MA", "name":"Marokko", "DEÜV":"252", "nationality":"marokkanisch"},
    {"value":"MAR", "name":"Marshallinseln", "DEÜV":"544", "nationality":"marshallisch"},
    {"value":"MAT", "name":"Martinique", "DEÜV":"129", "nationality":"französisch"},
    {"value":"RIM", "name":"Mauretanien", "DEÜV":"239", "nationality":"mauretanisch"},
    {"value":"MS", "name":"Mauritius", "DEÜV":"253", "nationality":"mauritisch"},
    {"value":"MAY", "name":"Mayotte", "DEÜV":"129", "nationality":"französisch"},
    {"value":"E", "name":"Melilla", "DEÜV":"161", "nationality":"spanisch"},
    {"value":"MEX", "name":"Mexiko", "DEÜV":"353", "nationality":"mexikanisch"},
    {"value":"MIK", "name":"Mikronesien", "DEÜV":"545", "nationality":"mikronesisch"},
    {"value":"MD", "name":"Moldau", "DEÜV":"146", "nationality":"moldauisch"},
    {"value":"MC", "name":"Monaco", "DEÜV":"147", "nationality":"monegassisch"},
    {"value":"MON", "name":"Mongolei", "DEÜV":"457", "nationality":"mongolisch"},
    {"value":"MNE", "name":"Montenegro", "DEÜV":"140", "nationality":"montenegrinisch"},
    {"value":"MOT", "name":"Montserrat", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"MOZ", "name":"Mosambik", "DEÜV":"254", "nationality":"mosambikanisch"},
    {"value":"MYA", "name":"Myanmar", "DEÜV":"427", "nationality":"myanmarisch"},
    {"value":"SWA", "name":"Namibia", "DEÜV":"267", "nationality":"namibisch"},
    {"value":"NAU", "name":"Nauru", "DEÜV":"531", "nationality":"nauruisch"},
    {"value":"UM", "name":"Navassa", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"NEP", "name":"Nepal", "DEÜV":"458", "nationality":"nepalesisch"},
    {"value":"NKA", "name":"Neukaledonien", "DEÜV":"129", "nationality":"französisch"},
    {"value":"NZ", "name":"Neuseeland", "DEÜV":"536", "nationality":"neuseeländisch"},
    {"value":"AQ", "name":"Neuseeländische Antarktis", "DEÜV":"536", "nationality":"neuseeländisch"},
    {"value":"NIC", "name":"Nicaragua", "DEÜV":"354", "nationality":"nicaraguanisch"},
    {"value":"NL", "name":"Niederlande", "DEÜV":"148", "nationality":"niederländisch"},
    {"value":"RN", "name":"Niger", "DEÜV":"255", "nationality":"nigrisch"},
    {"value":"WAN", "name":"Nigeria", "DEÜV":"232", "nationality":"nigerianisch"},
    {"value":"NIU", "name":"Niue", "DEÜV":"536", "nationality":"neuseeländisch"},
    {"value":"MK", "name":"Nordmazedonien", "DEÜV":"144", "nationality":"mazedonisch"},
    {"value":"NMA", "name":"Nördliche Marianen", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"NF", "name":"Norfolkinsel", "DEÜV":"523", "nationality":"australisch"},
    {"value":"N", "name":"Norwegen", "DEÜV":"149", "nationality":"norwegisch"},
    {"value":"AQ", "name":"Norwegisches Antarktis-Territorium", "DEÜV":"149", "nationality":"norwegisch"},
    {"value":"MAO", "name":"Oman", "DEÜV":"456", "nationality":"omanisch"},
    {"value":"A", "name":"Österreich", "DEÜV":"151", "nationality":"österreichisch"},
    {"value":"PK", "name":"Pakistan", "DEÜV":"461", "nationality":"pakistanisch"},
    {"value":"PSE", "name":"Palästinensische Gebiete", "DEÜV":"459", "nationality":"ohne Bezeichnung"},
    {"value":"PAL", "name":"Palau", "DEÜV":"537", "nationality":"palauisch"},
    {"value":"PA", "name":"Panama", "DEÜV":"357", "nationality":"panamaisch"},
    {"value":"PNG", "name":"Papua-Neuguinea", "DEÜV":"538", "nationality":"papua-neuguineisch"},
    {"value":"PY", "name":"Paraguay", "DEÜV":"359", "nationality":"paraguayisch"},
    {"value":"PE", "name":"Peru", "DEÜV":"361", "nationality":"peruanisch"},
    {"value":"RP", "name":"Philippinen", "DEÜV":"462", "nationality":"philippinisch"},
    {"value":"PIT", "name":"Pitcairninseln", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"PL", "name":"Polen", "DEÜV":"152", "nationality":"polnisch"},
    {"value":"P", "name":"Portugal", "DEÜV":"153", "nationality":"portugiesisch"},
    {"value":"PRI", "name":"Puerto Rico", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"REU", "name":"Réunion", "DEÜV":"129", "nationality":"französisch"},
    {"value":"RWA", "name":"Ruanda", "DEÜV":"265", "nationality":"ruandisch"},
    {"value":"RO", "name":"Rumänien", "DEÜV":"154", "nationality":"rumänisch"},
    {"value":"RUS", "name":"Russische Föderation", "DEÜV":"160", "nationality":"russisch"},
    {"value":"SOL", "name":"Salomonen", "DEÜV":"524", "nationality":"salomonisch"},
    {"value":"Z", "name":"Sambia", "DEÜV":"257", "nationality":"sambisch"},
    {"value":"WS", "name":"Samoa", "DEÜV":"543", "nationality":"samoanisch"},
    {"value":"RSM", "name":"San Marino", "DEÜV":"156", "nationality":"san-marinesisch"},
    {"value":"STP", "name":"São Tomé und Príncipe", "DEÜV":"268", "nationality":"são-toméisch"},
    {"value":"SAU", "name":"Saudi-Arabien", "DEÜV":"472", "nationality":"saudi-arabisch"},
    {"value":"S", "name":"Schweden", "DEÜV":"157", "nationality":"schwedisch"},
    {"value":"CH", "name":"Schweiz", "DEÜV":"158", "nationality":"schweizerisch"},
    {"value":"SN", "name":"Senegal", "DEÜV":"269", "nationality":"senegalesisch"},
    {"value":"SRB", "name":"Serbien", "DEÜV":"170", "nationality":"serbisch"},
    {"value":"SY", "name":"Seychellen", "DEÜV":"271", "nationality":"seychellisch"},
    {"value":"WAL", "name":"Sierra Leone", "DEÜV":"272", "nationality":"sierra-leonisch"},
    {"value":"ZW", "name":"Simbabwe", "DEÜV":"233", "nationality":"simbabwisch"},
    {"value":"SGP", "name":"Singapur", "DEÜV":"474", "nationality":"singapurisch"},
    {"value":"SK", "name":"Slowakei", "DEÜV":"155", "nationality":"slowakisch"},
    {"value":"SLO", "name":"Slowenien", "DEÜV":"131", "nationality":"slowenisch"},
    {"value":"SP", "name":"Somalia", "DEÜV":"273", "nationality":"somalisch"},
    {"value":"E", "name":"Spanien", "DEÜV":"161", "nationality":"spanisch"},
    {"value":"E", "name":"Spanische Hoheitsplätze in Nordafrika", "DEÜV":"161", "nationality":"spanisch"},
    {"value":"CL", "name":"Sri Lanka", "DEÜV":"431", "nationality":"sri-lankisch"},
    {"value":"BL", "name":"St. Barthélemy", "DEÜV":"129", "nationality":"französisch"},
    {"value":"HEL", "name":"St. Helena", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"HEL", "name":"St. Helena, Ascension und Tristan da Cunha", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"SCN", "name":"St. Kitts und Nevis", "DEÜV":"370", "nationality":"von St. Kitts und Nevis"},
    {"value":"WL", "name":"St. Lucia", "DEÜV":"366", "nationality":"lucianisch"},
    {"value":"MF", "name":"St. Martin (französischer Teil)", "DEÜV":"129", "nationality":"französisch"},
    {"value":"SX", "name":"St. Martin (niederländischer Teil)", "DEÜV":"148", "nationality":"niederländisch"},
    {"value":"PIE", "name":"Saint Pierre und Miquelon", "DEÜV":"129", "nationality":"französisch"},
    {"value":"WV", "name":"St. Vincent und die Grenadinen", "DEÜV":"369", "nationality":"vincentisch"},
    {"value":"ZA", "name":"Südafrika", "DEÜV":"263", "nationality":"südafrikanisch"},
    {"value":"SDN", "name":"Sudan", "DEÜV":"277", "nationality":"sudanesisch"},
    {"value":"GS", "name":"Südgeorgien und die südlichen Sandwichinseln", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"SSD", "name":"Südsudan", "DEÜV":"278", "nationality":"südsudanesisch"},
    {"value":"SME", "name":"Suriname", "DEÜV":"364", "nationality":"surinamisch"},
    {"value":"SJ", "name":"Svalbard und Jan Mayen", "DEÜV":"149", "nationality":"norwegisch"},
    {"value":"SYR", "name":"Syrien", "DEÜV":"475", "nationality":"syrisch"},
    {"value":"TAD", "name":"Tadschikistan", "DEÜV":"470", "nationality":"tadschikisch"},
    {"value":"TWN", "name":"Taiwan", "DEÜV":"465", "nationality":"taiwanisch"},
    {"value":"EAT", "name":"Tansania", "DEÜV":"282", "nationality":"tansanisch"},
    {"value":"T", "name":"Thailand", "DEÜV":"476", "nationality":"thailändisch"},
    {"value":"TJ", "name":"Tibet", "DEÜV":"479", "nationality":"chinesisch"},
    {"value":"OTI", "name":"Timor-Leste", "DEÜV":"483", "nationality":"von Timor-Leste"},
    {"value":"TG", "name":"Togo", "DEÜV":"283", "nationality":"togoisch"},
    {"value":"TOK", "name":"Tokelau", "DEÜV":"536", "nationality":"neuseeländisch"},
    {"value":"TON", "name":"Tonga", "DEÜV":"541", "nationality":"tongaisch"},
    {"value":"TT", "name":"Trinidad und Tobago", "DEÜV":"371", "nationality":"von Trinidad und Tobago"},
    {"value":"HEL", "name":"Tristan da Cunha", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"CHD", "name":"Tschad", "DEÜV":"284", "nationality":"tschadisch"},
    {"value":"CZ", "name":"Tschechien", "DEÜV":"164", "nationality":"tschechisch"},
    {"value":"TN", "name":"Tunesien", "DEÜV":"285", "nationality":"tunesisch"},
    {"value":"TR", "name":"Türkei", "DEÜV":"163", "nationality":"türkisch"},
    {"value":"TUR", "name":"Turkmenistan", "DEÜV":"471", "nationality":"turkmenisch"},
    {"value":"TUC", "name":"Turks- und Caicosinseln", "DEÜV":"185", "nationality":"britisch (BOTC)"},
    {"value":"TUV", "name":"Tuvalu", "DEÜV":"540", "nationality":"tuvaluisch"},
    {"value":"EAU", "name":"Uganda", "DEÜV":"286", "nationality":"ugandisch"},
    {"value":"UA", "name":"Ukraine", "DEÜV":"166", "nationality":"ukrainisch"},
    {"value":"H", "name":"Ungarn", "DEÜV":"165", "nationality":"ungarisch"},
    {"value":"ROU", "name":"Uruguay", "DEÜV":"365", "nationality":"uruguayisch"},
    {"value":"USB", "name":"Usbekistan", "DEÜV":"477", "nationality":"usbekisch"},
    {"value":"VAN", "name":"Vanuatu", "DEÜV":"532", "nationality":"vanuatuisch"},
    {"value":"V", "name":"Vatikanstadt", "DEÜV":"167", "nationality":"vatikanisch"},
    {"value":"YV", "name":"Venezuela", "DEÜV":"367", "nationality":"venezolanisch"},
    {"value":"UAE", "name":"Vereinigte Arabische Emirate", "DEÜV":"469", "nationality":"der Vereinigten Arabischen"},
    {"value":"USA", "name":"Vereinigte Staaten", "DEÜV":"368", "nationality":"amerikanisch"},
    {"value":"GB", "name":"Vereinigtes Königreich", "DEÜV":"168", "nationality":"britisch"},
    {"value":"VN", "name":"Vietnam", "DEÜV":"432", "nationality":"vietnamesisch"},
    {"value":"WF", "name":"Wallis und Futuna", "DEÜV":"129", "nationality":"französisch"},
    {"value":"CX", "name":"Weihnachtsinsel", "DEÜV":"523", "nationality":"australisch"},
    {"value":"EH", "name":"Westsahara", "DEÜV":"252", "nationality":"marokkanisch"},
    {"value":"RCA", "name":"Zentralafrikanische Republik", "DEÜV":"289", "nationality":"zentralafrikanisch"},
    {"value":"CY", "name":"Zypern", "DEÜV":"181", "nationality":"zyprisch"},
    
],

"bgs": [

    ["BG BAU Bezirk Böblingen", "67350937" ],
    ["BG BAU Bezirk Frankfurt", "44888436" ],
    ["BG BAU Bezirk Hamburg", "15087927" ],
    ["BG BAU Bezirk Hannover", "29036720" ],
    ["BG BAU Bezirk Karlsruhe", "62279404" ],
    ["BG BAU Bezirk München (Hochbau)", "87661138" ],
    ["BG BAU Bezirk München (Tiefbau)", "87661183" ],
    ["BG BAU Bezirk Wuppertal", "42884688" ],
    ["BG BAU", "14066582" ],
    ["BG ETEM", "37916971" ],
    ["BG Holz und Metall", "52742028" ],
    ["BG Metall Nord", "29059513" ],
    ["BG RCI - Baustoffe-Steine-Erden", "29029801" ],
    ["BG RCI - Bergbau", "31608112" ],
    ["BG RCI - Chemie", "61635458" ],
    ["BG RCI - Lederindustrie", "52717470" ],
    ["BG RCI - Papierherstellung", "18484827" ],
    ["BG RCI - Zucker", "18484877" ],
    ["BG Verkehr - Fahrzeughaltungen", "15141364" ],
    ["BG Verkehr - Post und Telekom", "66337061" ],
    ["BG Verkehr - Seeschifffahrt", "99011352" ],
    ["BGETEM - Energie und Wasserwirtsch.", "34364294" ],
    ["BGHW", "32064004" ],
    ["BGN - Fleischwirtschaft", "52738475" ],
    ["BGN", "63800761" ],
    ["BGW Gesundheitsd. und Wohlfahrtspf.", "15186676" ],
    ["Braunschweigischer GUVV", "21204943" ],
    ["Feuerwehr-UK Mitte - Sachsen-Anhalt", "98705576" ],
    ["Feuerwehr-UK Mitte - Thüringen", "09322747" ],
    ["Feuerwehr-UK Niedersachsen", "29214533" ],
    ["Feuerwehr-UK Nord - Hamburg", "18645029" ],
    ["Feuerwehr-UK Nord - Kiel", "13385729" ],
    ["Feuerwehr-UK Nord - Schwerin", "01627953" ],
    ["GUV Hannover", "29086457" ],
    ["GUV Oldenburg", "26125562" ],
    ["Kommunale Unfallversicherung Bayern", "18477668" ],
    ["Kommunale Unfallversicherung Bayern", "87661207" ],
    ["LUK Niedersachsen", "18626026" ],
    ["SVLFG, LBG, Standort Bayreuth", "72305544" ],
    ["SVLFG, LBG, Standort Darmstadt", "47042806" ],
    ["SVLFG, LBG, Standort Hannover", "29139336" ],
    ["SVLFG, LBG, Standort Hoppegarten", "08270878" ],
    ["SVLFG, LBG, Standort Kassel", "47009510" ],
    ["SVLFG, LBG, Standort Kiel", "13174962" ],
    ["SVLFG, LBG, Standort Landshut", "87108525" ],
    ["SVLFG, LBG, Standort Münster", "39892693" ],
    ["SVLFG, LBG, Standort Stuttgart", "67545123" ],
    ["UK Baden-Württemberg", "67334480" ],
    ["UK Berlin", "90276713" ],
    ["UK Bremen", "20345417" ],
    ["UK Hessen", "44861264" ],
    ["UK Mecklenburg-Vorpommern", "01681222" ],
    ["UK Nord", "16716004" ],
    ["UK Nordrhein-Westfalen", "34239086" ],
    ["UK Rheinland-Pfalz", "53149588" ],
    ["UK Saarland", "55423519" ],
    ["UK Sachsen", "01064065" ],
    ["UK Sachsen-Anhalt", "03701377" ],
    ["UK Thüringen", "07235792" ],
    ["UK und Feuerw.-UK Brandenburg", "02379637" ],
    ["UV Bund und Bahn - Bahn", "49005902" ],
    ["UV Bund und Bahn - Bund", "28143238" ],
    ["VBG", "15250094" ],
],

"taxClass": [
    {"value": "","name":""},
    {"value": "0", "name": "Keine Steuerkarte (Ausland, geringfügig Beschäftigte)"},
    {"value": "1", "name": "Steuerklasse I"},
    {"value": "2", "name": "Steuerklasse II",},
    {"value": "3", "name": "Steuerklasse III"},
    {"value": "4", "name": "Steuerklasse IV"},
    {"value": "5", "name": "Steuerklasse V"},
    {"value": "6", "name": "Steuerklasse VI"},
    {"value": "-999", "name": "Das weiß ich nicht"},
],

"additionalOccupation": [
    {"value": "", "name": ""},
    {"value": "0", "name": "Nein"},
    {"value": "1", "name": "Ja"},
    {"value": "-999", "name": "Das weiß ich nicht"},
],

"ELSTAMOcclusion": [
    {"value": "", "name": ""},
    {"value": "0", "name": "Nein"},
    {"value": "1", "name": "Ja"},
    {"value": "-999", "name": "Das weiß ich nicht"},
],

"religiousDenomination": [
    {"value": "", "name": ""},
    {"value":"ak", "name":"Altkatholische Kirschensteuer"},
    {"value":"ev", "name":"Evangelische Kirschensteuer"},
    {"value":"fa", "name":"Freie Religionsgemeinschaft Alzey"},
    {"value":"fb", "name":"Kirschensteuer der Freireligiösen Landesgemeinde Baden"},
    {"value":"fg", "name":"Freireligiöse Landesgemeinde Pfalz"},
    {"value":"fm", "name":"Freireligiöse Gemeinde Mainz"},
    {"value":"fr", "name":"französisch reformiert"},
    {"value":"fs", "name":"Freireligiöse Gemeinde Offenbach"},
    {"value":"ib", "name":"Kirschensteuer der Israelitischen Religionsgemeinschaft Baden"},
    {"value":"il", "name":"Israelitische Kultussteuer der Kultusberechtigen Gemeinden"},
    {"value":"is", "name":"Israelitische Kultussteuer Frankfurt"},
    {"value":"iw", "name":"Kirschensteuer der Israelitischen Religionsgemeinschaft Württemberg"},
    {"value":"jd", "name":"Jüdische Kultussteuer"},
    {"value":"lt", "name":"Evangelisch lutherisch"},
    {"value":"rf", "name":"Evangelisch reformiert"},
    {"value":"rk", "name":"Römisch-Katholische Kirschensteuer"},
    {"value":"vd", "name":"ohne Konfession"},
    {"value":"-999", "name":"andere"},
],

"specialIncomeTaxTable": [
    {"value": "","name":""},
    {"value":"X", "name":"keine Anwendung der besonderen Lohnsteuertabelle bzw_ Abrechnung 'normal steuerpflichtig'"},
    {"value":"A", "name":"beschränkt steuerpflichtige Arbeitnehmer, normale Lohnsteuertabelle"},
    {"value":"B", "name":"Anwendung der besonderen Lohnsteuertabelle. Sobald jedoch über den Beitragsgruppenschlüssel Rentenversicherung oder über Lohnarten mit Bezugsart 37 Zuschüsse zur befreienden Lebensversicherung abgerechnet werden, wird trotz Eintragung B die allgemeine Tabelle angewendet_ Die Ausgabe des Großbuchstaben 8 auf der Lohnsteuerbescheinigung entfällt ab 2010. Die Eintragung B muss aber bleiben um zu erkennen, wie in der Steuerberechnung zu verfahren ist"},
    {"value":"C", "name":"beschränkt steuerpflichtige Arbeitnehmer, besondere Lohnsteuertabelle"},
    {"value":"G", "name":"belgische Grenzgänger (Kürzung der Lohnsteuer und des Solidaritätszuschlages) jeweils um 8%"},
    {"value":"H", "name":"belgische Grenzgänger (Berechnung wie bei Schlüssel G jedoch wird die B-Tabelle herangezogen)"},
    {"value":"N", "name":"Normalsteuer"},
    {"value":"S", "name":"Schweizer Grenzgänger (besondere Lohnsteuerberechnung nach Art_ 15a Doppelbesteuerungsabkommen Schweiz)"},
    {"value":"T", "name":"Schweizer Grenzgänger (besondere Lohnsteuerberechnung wie unter Schlüssel S jedoch wird die besondere Lohnsteuertabelle herangezogen)"},
],

"officialOrPV": [
    {"value": "", "name": ""},
    {"value": "0", "name": "Nein"},
    {"value": "1", "name": "Ja"},
    {"value": "-999", "name": "Das weiß ich nicht"},
],

"federalState": [

    ["Baden-Württemberg", "61" ],
    ["Bayern", "70" ],
    ["Berlin", "1" ],
    ["Berlin (Ost),Neue BL.", "81" ],
    ["Brandenburg", "84" ],
    ["Bremen", "21" ],
    ["Bremerhaven", "22" ],
    ["Hamburg", "11" ],
    ["Hessen", "51" ],
    ["Hessen, Jüd.Konf.", "52" ],
    ["Mecklenburg-Vorpommern", "82" ],
    ["Neue Bundesländer", "80" ],
    ["Niedersachsen", "30" ],
    ["Nordrhein-Westfalen", "41" ],
    ["Rheinland-Pfalz", "42" ],
    ["Saarland", "43" ],
    ["Sachsen", "89" ],
    ["Sachsen-Anhalt", "88" ],
    ["Schlesw.Holst.Nordelbisch", "13" ],
    ["Schleswig-Holstein", "12" ],
    ["Thüringen", "83" ],
],

"wageTaxComp": [
    ["Keinen maschinellen Lohnsteuerjahresausgleich durchzuführen. Bei rentenversicherungsfreien Arbeitnehmern (z.B. Beamte) ist der Jahresausgleich nicht mehr zulässig, wenn die Steuer nicht während des gesamten Jahres nach derselben Tabelle ermittelt wurde.", "0"],
    ["Maschineller Jahresausgleich, ohne Berücksichtigung von Bezügen für mehrere Kalenderjahre", "1"],
    ["Wie 1, jedoch ohne Kirchensteuerausgleich", "2"],
    ["Wie 1, jedoch mit Einbeziehung von Bezügen für mehrere Kalenderjahre", "3"],
    ["Wie 3, jedoch ohne Kirchensteuerausgleich. Die Schlüssel 3 bzw. 4 sind nur dann einzusetzen, wenn der Arbeitnehmer die Einbeziehung der dort genannten Bezüge beantragt.", "4"],
    ["Permanenter Lohn- und Kirchensteuerjahresausgleich", "8"],
    ["Wie 8, jedoch ohne Kirchensteuerausgleich. Der Lohnsteuerjahresausgleich (auch der permanente) wird nur unter bestimmten Voraussetzungen durchgeführt (siehe auch Teil 3 - Kap. 3.4.3 bzw. Teil 7 - Kap. 12 ).", "9"]
],

"maritalStatus": [
    {"value": "","name":""},
    {"value": "G","name":"geschieden"},
    {"value": "L","name":"ledig"},
    {"value": "N","name":"nicht verheiratet"},
    {"value": "P","name":"eingetragene Partnerschaft"},
    {"value": "T","name":"getrennt lebend"},
    {"value": "V","name":"verheiratet"},
    {"value": "W","name":"verwitwet"}
],

"companies": [
    {"value": "","name":""},
    {"value": "1","name":"DAA-Technikum"},
    {"value": "2","name":"DAA-GMBH"},
    {"value": "4","name":"HFH"},
    {"value": "5","name":"GOB"},
    {"value": "6","name":"Bplusr"},
],

"schoolGraduation": [
    {"value": "","name":""},
    {"value": "1","name":"ohne"},
    {"value": "2","name":"Hauptschule"},
    {"value": "3","name":"Mittlere Reife"},
    {"value": "4","name":"Abitur"},
    {"value": "9","name":"unbekannt"},
],

"jobGraduation": [
    {"value": "","name":""},
    {"value": "1","name":"ohne"},
    {"value": "2","name":"Berufsausbildung"},
    {"value": "3","name":"Meister o. Fachschulabschluss"},
    {"value": "4","name":"Bachelor"},
    {"value": "5","name":"Diplom/Magister/Master/Staatsexamen"},
    {"value": "6","name":"Promotion"},
    {"value": "9","name":"unbekannt"},
],

"healthInsuranceType": [ 
    {"value": "","name":""},
    {"value": "1","name":"gesetzlich pflichtversichert"},
    {"value": "2","name":"gesetzlich freiwillig versichert"},
    {"value": "3","name":"privat versichert"},
],

"healthInsuranceCompany": [
    {"value": "","name":""},
    {"value": "-999", "name": "Das weiß ich nicht"},
    {"value": "006", "name": "AOK - Niedersachsen", "KKframe": "0000100"},
    {"value": "011", "name": "AOK Baden-Württemberg", "KKframe": "0000100"},
    {"value": "012", "name": "AOK Bayern Die Gesundheitskasse", "KKframe": "0000100"},
    {"value": "005", "name": "AOK Bremen/Bremerhaven", "KKframe": "0000100"},
    {"value": "009", "name": "AOK Hessen", "KKframe": "0000100"},
    {"value": "013", "name": "AOK Nordost in Berlin", "KKframe": "0000100"},
    {"value": "007", "name": "AOK NordWest", "KKframe": "0000100"},
    {"value": "004", "name": "AOK PLUS", "KKframe": "0000100"},
    {"value": "008", "name": "AOK Rheinland/Hamburg Die Gesundheitskasse", "KKframe": "0000100"},
    {"value": "010", "name": "AOK Rheinland-Pfalz/Saarland", "KKframe": "0000100"},
    {"value": "003", "name": "AOK Sachsen-Anhalt", "KKframe": "0000100"},
    {"value": "280", "name": "Audi BKK", "KKframe": "0000100"},
    {"value": "304", "name": "Augenoptiker Ausgleichskasse", "KKframe": "0000100"},
    {"value": "249", "name": "BAHN-BKK", "KKframe": "0000100"},
    {"value": "105", "name": "BARMER (vormals BARMER GE", "KKframe": "0000100"},
    {"value": "221", "name": "Bertelsmann BKK", "KKframe": "0000100"},
    {"value": "224", "name": "Betriebskrankenkasse Dürkopp Adler", "KKframe": "0000100"},
    {"value": "273", "name": "Betriebskrankenkasse VerbundPlus", "KKframe": "0000100"},
    {"value": "251", "name": "Betriebskrankenkasse der EVM", "KKframe": "0000100"},
    {"value": "255", "name": "Betriebskrankenkasse Groz-Beckert", "KKframe": "0000100"},
    {"value": "306", "name": "BIG direkt gesund", "KKframe": "0000100"},
    {"value": "274", "name": "BKK Akzo Nobel Obernburg", "KKframe": "0000100"},
    {"value": "244", "name": "BKK B. Braun Melsungen AG", "KKframe": "0000100"},
    {"value": "219", "name": "BKK BPW Wiehl", "KKframe": "0000100"},
    {"value": "227", "name": "BKK Deutsche Bank AG", "KKframe": "0000100"},
    {"value": "222", "name": "BKK Diakonie", "KKframe": "0000100"},
    {"value": "218", "name": "BKK EUREGIO", "KKframe": "0000100"},
    {"value": "214", "name": "BKK EWE", "KKframe": "0000100"},
    {"value": "211", "name": "BKK exklusiv", "KKframe": "0000100"},
    {"value": "281", "name": "BKK Faber-Castell & Partner", "KKframe": "0000100"},
    {"value": "208", "name": "BKK firmus", "KKframe": "0000100"},
    {"value": "260", "name": "BKK FREUDENBERG", "KKframe": "0000100"},
    {"value": "225", "name": "BKK GILDEMEISTER SEIDENSTICKER", "KKframe": "0000100"},
    {"value": "243", "name": "BKK Herkules", "KKframe": "0000100"},
    {"value": "278", "name": "BKK KBA/West", "KKframe": "0000100"},
    {"value": "247", "name": "BKK Linde", "KKframe": "0000100"},
    {"value": "268", "name": "BKK MAHLE", "KKframe": "0000100"},
    {"value": "230", "name": "BKK Melitta Plus", "KKframe": "0000100"},
    {"value": "223", "name": "BKK Miele", "KKframe": "0000100"},
    {"value": "203", "name": "BKK Mobil Oil", "KKframe": "0000100"},
    {"value": "261", "name": "BKK MTU", "KKframe": "0000100"},
    {"value": "250", "name": "BKK PFAFF", "KKframe": "0000100"},
    {"value": "253", "name": "BKK Pfalz", "KKframe": "0000100"},
    {"value": "284", "name": "BKK ProVita (ehemals BKK)", "KKframe": "0000100"},
    {"value": "210", "name": "BKK Public", "KKframe": "0000100"},
    {"value": "245", "name": "BKK PwC", "KKframe": "0000100"},
    {"value": "266", "name": "BKK Rieker.RICOSTA.Weisse", "KKframe": "0000100"},
    {"value": "209", "name": "BKK Salzgitter", "KKframe": "0000100"},
    {"value": "256", "name": "BKK Scheufelen", "KKframe": "0000100"},
    {"value": "265", "name": "BKK Schwarzwald-Baar-Heub", "KKframe": "0000100"},
    {"value": "279", "name": "BKK Stadt Augsburg", "KKframe": "0000100"},
    {"value": "212", "name": "BKK Technoform", "KKframe": "0000100"},
    {"value": "275", "name": "BKK Textilgruppe Hof", "KKframe": "0000100"},
    {"value": "232", "name": "BKK VDN", "KKframe": "0000100"},
    {"value": "285", "name": "BKK Verkehrsbau Union", "KKframe": "0000100"},
    {"value": "286", "name": "BKK Voralb", "KKframe": "0000100"},
    {"value": "237", "name": "BKK Werra-Meissner", "KKframe": "0000100"},
    {"value": "241", "name": "BKK Wirtschaft & Finanzen", "KKframe": "0000100"},
    {"value": "267", "name": "BKK Würth", "KKframe": "0000100"},
    {"value": "272", "name": "BKK ZF & Partner", "KKframe": "0000100"},
    {"value": "213", "name": "BKK24", "KKframe": "0000100"},
    {"value": "282", "name": "BMW BKK Zentrale", "KKframe": "0000100"},
    {"value": "269", "name": "BOSCH BKK", "KKframe": "0000100"},
    {"value": "288", "name": "CITY BKK", "KKframe": "0000100"},
    {"value": "287", "name": "CITY BKK / Ost", "KKframe": "0000100"},
    {"value": "226", "name": "Continentale Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "270", "name": "Daimler Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "106", "name": "DAK-Gesundheit (ehem.BKK-)", "KKframe": "0000100"},
    {"value": "252", "name": "Debeka BKK", "KKframe": "0000100"},
    {"value": "236", "name": "DIE BERGISCHE KRANKENKASSE", "KKframe": "0000100"},
    {"value": "263", "name": "Die Schwenninger Krankenkasse", "KKframe": "0000100"},
    {"value": "216", "name": "energie-BKK", "KKframe": "0000100"},
    {"value": "240", "name": "Ernst & Young BKK", "KKframe": "0000100"},
    {"value": "220", "name": "Heimat Krankenkasse", "KKframe": "0000100"},
    {"value": "101", "name": "HEK", "KKframe": "0000100"},
    {"value": "103", "name": "hkk Krankenkasse", "KKframe": "0000100"},
    {"value": "301", "name": "IKK Brandenburg und Berlin", "KKframe": "0000100"},
    {"value": "302", "name": "IKK classic", "KKframe": "0000100"},
    {"value": "300", "name": "IKK gesund plus (Ost)", "KKframe": "0000100"},
    {"value": "303", "name": "IKK Nord", "KKframe": "0000100"},
    {"value": "305", "name": "IKK Südwest", "KKframe": "0000100"},
    {"value": "246", "name": "KARL MAYER Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "104", "name": "KKH Kaufmännische Krankenkasse", "KKframe": "0000100"},
    {"value": "401", "name": "Knappschaft (Allgemein)", "KKframe": "0000100"},
    {"value": "402", "name": "Knappschaft (besondere)", "KKframe": "0000100"},
    {"value": "276", "name": "Krones BKK", "KKframe": "0000100"},
    {"value": "238", "name": "Merck BKK", "KKframe": "0000100"},
    {"value": "259", "name": "mhplus Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "229", "name": "NOVITAS", "KKframe": "0000100"},
    {"value": "205", "name": "pronova BKK", "KKframe": "0000100"},
    {"value": "248", "name": "R+V Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "239", "name": "Salus BKK", "KKframe": "0000100"},
    {"value": "283", "name": "SBK HV West", "KKframe": "0000100"},
    {"value": "204", "name": "SECURVITA BKK", "KKframe": "0000100"},
    {"value": "277", "name": "SKD BKK", "KKframe": "0000100"},
    {"value": "258", "name": "Südzucker BKK", "KKframe": "0000100"},
    {"value": "507", "name": "SVLFG, Landw. Krankenkasse", "KKframe": "0000100"},
    {"value": "500", "name": "SVLFG, Landwirtschaftlich (Berlin, Brandenburg, Mecklenburg-Vorpommern, Sachsen-Anhalt, Sachsen und Thüringen)", "KKframe": "0000100"},
    {"value": "501", "name": "SVLFG, Landwirtschaftlich (Für Betriebe des Gartenbaus (Ost))", "KKframe": "0000100"},
    {"value": "502", "name": "SVLFG, Landwirtschaftlich (Schleswig-Holstein und Hamburg)", "KKframe": "0000100"},
    {"value": "503", "name": "SVLFG, Landwirtschaftlich (Niedersachsen und Bremen)", "KKframe": "0000100"},
    {"value": "504", "name": "SVLFG, Landwirtschaftlich (Nordrhein-Westfalen)", "KKframe": "0000100"},
    {"value": "505", "name": "SVLFG, Landwirtschaftlich (Hessen, Rheinland-Pfalz und Saarland)", "KKframe": "0000100"},
    {"value": "506", "name": "SVLFG, Landwirtschaftlich (Für Betriebe des Gartenbaus (West))", "KKframe": "0000100"},
    {"value": "508", "name": "SVLFG, Landwirtschaftlich (Bayern (Franken und Oberbayern))", "KKframe": "0000100"},
    {"value": "509", "name": "SVLFG, Landwirtschaftlich (Bayern (Niederbayern/Oberpfalz und Schwaben))", "KKframe": "0000100"},
    {"value": "100", "name": "Techniker Krankenkasse", "KKframe": "0000100"},
    {"value": "215", "name": "TUI BKK", "KKframe": "0000100"},
    {"value": "233", "name": "VIACTIV Krankenkasse", "KKframe": "0000100"},
    {"value": "257", "name": "WMF BKK", "KKframe": "0000100"},


],

"healthInsuranceCompany2": [
    {"value": "","name":""},
    {"value": "-999", "name": "Das weiß ich nicht"},
    {"value": "006", "name": "AOK - Niedersachsen", "KKframe": "0000100"},
    {"value": "011", "name": "AOK Baden-Württemberg", "KKframe": "0000100"},
    {"value": "012", "name": "AOK Bayern Die Gesundheitskasse", "KKframe": "0000100"},
    {"value": "005", "name": "AOK Bremen/Bremerhaven", "KKframe": "0000100"},
    {"value": "009", "name": "AOK Hessen", "KKframe": "0000100"},
    {"value": "013", "name": "AOK Nordost in Berlin", "KKframe": "0000100"},
    {"value": "007", "name": "AOK NordWest", "KKframe": "0000100"},
    {"value": "004", "name": "AOK PLUS", "KKframe": "0000100"},
    {"value": "008", "name": "AOK Rheinland/Hamburg Die Gesundheitskasse", "KKframe": "0000100"},
    {"value": "010", "name": "AOK Rheinland-Pfalz/Saarland", "KKframe": "0000100"},
    {"value": "003", "name": "AOK Sachsen-Anhalt", "KKframe": "0000100"},
    {"value": "280", "name": "Audi BKK", "KKframe": "0000100"},
    {"value": "304", "name": "Augenoptiker Ausgleichskasse", "KKframe": "0000100"},
    {"value": "249", "name": "BAHN-BKK", "KKframe": "0000100"},
    {"value": "105", "name": "BARMER (vormals BARMER GE", "KKframe": "0000100"},
    {"value": "221", "name": "Bertelsmann BKK", "KKframe": "0000100"},
    {"value": "224", "name": "Betriebskrankenkasse Dürkopp Adler", "KKframe": "0000100"},
    {"value": "273", "name": "Betriebskrankenkasse VerbundPlus", "KKframe": "0000100"},
    {"value": "251", "name": "Betriebskrankenkasse der EVM", "KKframe": "0000100"},
    {"value": "255", "name": "Betriebskrankenkasse Groz-Beckert", "KKframe": "0000100"},
    {"value": "306", "name": "BIG direkt gesund", "KKframe": "0000100"},
    {"value": "274", "name": "BKK Akzo Nobel Obernburg", "KKframe": "0000100"},
    {"value": "244", "name": "BKK B. Braun Melsungen AG", "KKframe": "0000100"},
    {"value": "219", "name": "BKK BPW Wiehl", "KKframe": "0000100"},
    {"value": "227", "name": "BKK Deutsche Bank AG", "KKframe": "0000100"},
    {"value": "222", "name": "BKK Diakonie", "KKframe": "0000100"},
    {"value": "218", "name": "BKK EUREGIO", "KKframe": "0000100"},
    {"value": "214", "name": "BKK EWE", "KKframe": "0000100"},
    {"value": "211", "name": "BKK exklusiv", "KKframe": "0000100"},
    {"value": "281", "name": "BKK Faber-Castell & Partner", "KKframe": "0000100"},
    {"value": "208", "name": "BKK firmus", "KKframe": "0000100"},
    {"value": "260", "name": "BKK FREUDENBERG", "KKframe": "0000100"},
    {"value": "225", "name": "BKK GILDEMEISTER SEIDENSTICKER", "KKframe": "0000100"},
    {"value": "243", "name": "BKK Herkules", "KKframe": "0000100"},
    {"value": "278", "name": "BKK KBA/West", "KKframe": "0000100"},
    {"value": "247", "name": "BKK Linde", "KKframe": "0000100"},
    {"value": "268", "name": "BKK MAHLE", "KKframe": "0000100"},
    {"value": "230", "name": "BKK Melitta Plus", "KKframe": "0000100"},
    {"value": "223", "name": "BKK Miele", "KKframe": "0000100"},
    {"value": "203", "name": "BKK Mobil Oil", "KKframe": "0000100"},
    {"value": "261", "name": "BKK MTU", "KKframe": "0000100"},
    {"value": "250", "name": "BKK PFAFF", "KKframe": "0000100"},
    {"value": "253", "name": "BKK Pfalz", "KKframe": "0000100"},
    {"value": "284", "name": "BKK ProVita (ehemals BKK)", "KKframe": "0000100"},
    {"value": "210", "name": "BKK Public", "KKframe": "0000100"},
    {"value": "245", "name": "BKK PwC", "KKframe": "0000100"},
    {"value": "266", "name": "BKK Rieker.RICOSTA.Weisse", "KKframe": "0000100"},
    {"value": "209", "name": "BKK Salzgitter", "KKframe": "0000100"},
    {"value": "256", "name": "BKK Scheufelen", "KKframe": "0000100"},
    {"value": "265", "name": "BKK Schwarzwald-Baar-Heub", "KKframe": "0000100"},
    {"value": "279", "name": "BKK Stadt Augsburg", "KKframe": "0000100"},
    {"value": "212", "name": "BKK Technoform", "KKframe": "0000100"},
    {"value": "275", "name": "BKK Textilgruppe Hof", "KKframe": "0000100"},
    {"value": "232", "name": "BKK VDN", "KKframe": "0000100"},
    {"value": "285", "name": "BKK Verkehrsbau Union", "KKframe": "0000100"},
    {"value": "286", "name": "BKK Voralb", "KKframe": "0000100"},
    {"value": "237", "name": "BKK Werra-Meissner", "KKframe": "0000100"},
    {"value": "241", "name": "BKK Wirtschaft & Finanzen", "KKframe": "0000100"},
    {"value": "267", "name": "BKK Würth", "KKframe": "0000100"},
    {"value": "272", "name": "BKK ZF & Partner", "KKframe": "0000100"},
    {"value": "213", "name": "BKK24", "KKframe": "0000100"},
    {"value": "282", "name": "BMW BKK Zentrale", "KKframe": "0000100"},
    {"value": "269", "name": "BOSCH BKK", "KKframe": "0000100"},
    {"value": "288", "name": "CITY BKK", "KKframe": "0000100"},
    {"value": "287", "name": "CITY BKK / Ost", "KKframe": "0000100"},
    {"value": "226", "name": "Continentale Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "270", "name": "Daimler Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "106", "name": "DAK-Gesundheit (ehem.BKK-)", "KKframe": "0000100"},
    {"value": "252", "name": "Debeka BKK", "KKframe": "0000100"},
    {"value": "236", "name": "DIE BERGISCHE KRANKENKASSE", "KKframe": "0000100"},
    {"value": "263", "name": "Die Schwenninger Krankenkasse", "KKframe": "0000100"},
    {"value": "216", "name": "energie-BKK", "KKframe": "0000100"},
    {"value": "240", "name": "Ernst & Young BKK", "KKframe": "0000100"},
    {"value": "220", "name": "Heimat Krankenkasse", "KKframe": "0000100"},
    {"value": "101", "name": "HEK", "KKframe": "0000100"},
    {"value": "103", "name": "hkk Krankenkasse", "KKframe": "0000100"},
    {"value": "301", "name": "IKK Brandenburg und Berlin", "KKframe": "0000100"},
    {"value": "302", "name": "IKK classic", "KKframe": "0000100"},
    {"value": "300", "name": "IKK gesund plus (Ost)", "KKframe": "0000100"},
    {"value": "303", "name": "IKK Nord", "KKframe": "0000100"},
    {"value": "305", "name": "IKK Südwest", "KKframe": "0000100"},
    {"value": "246", "name": "KARL MAYER Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "104", "name": "KKH Kaufmännische Krankenkasse", "KKframe": "0000100"},
    {"value": "401", "name": "Knappschaft (Allgemein)", "KKframe": "0000100"},
    {"value": "402", "name": "Knappschaft (besondere)", "KKframe": "0000100"},
    {"value": "276", "name": "Krones BKK", "KKframe": "0000100"},
    {"value": "238", "name": "Merck BKK", "KKframe": "0000100"},
    {"value": "259", "name": "mhplus Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "229", "name": "NOVITAS", "KKframe": "0000100"},
    {"value": "205", "name": "pronova BKK", "KKframe": "0000100"},
    {"value": "248", "name": "R+V Betriebskrankenkasse", "KKframe": "0000100"},
    {"value": "239", "name": "Salus BKK", "KKframe": "0000100"},
    {"value": "283", "name": "SBK HV West", "KKframe": "0000100"},
    {"value": "204", "name": "SECURVITA BKK", "KKframe": "0000100"},
    {"value": "277", "name": "SKD BKK", "KKframe": "0000100"},
    {"value": "258", "name": "Südzucker BKK", "KKframe": "0000100"},
    {"value": "507", "name": "SVLFG, Landw. Krankenkasse", "KKframe": "0000100"},
    {"value": "500", "name": "SVLFG, Landwirtschaftlich (Berlin, Brandenburg, Mecklenburg-Vorpommern, Sachsen-Anhalt, Sachsen und Thüringen)", "KKframe": "0000100"},
    {"value": "501", "name": "SVLFG, Landwirtschaftlich (Für Betriebe des Gartenbaus (Ost))", "KKframe": "0000100"},
    {"value": "502", "name": "SVLFG, Landwirtschaftlich (Schleswig-Holstein und Hamburg)", "KKframe": "0000100"},
    {"value": "503", "name": "SVLFG, Landwirtschaftlich (Niedersachsen und Bremen)", "KKframe": "0000100"},
    {"value": "504", "name": "SVLFG, Landwirtschaftlich (Nordrhein-Westfalen)", "KKframe": "0000100"},
    {"value": "505", "name": "SVLFG, Landwirtschaftlich (Hessen, Rheinland-Pfalz und Saarland)", "KKframe": "0000100"},
    {"value": "506", "name": "SVLFG, Landwirtschaftlich (Für Betriebe des Gartenbaus (West))", "KKframe": "0000100"},
    {"value": "508", "name": "SVLFG, Landwirtschaftlich (Bayern (Franken und Oberbayern))", "KKframe": "0000100"},
    {"value": "509", "name": "SVLFG, Landwirtschaftlich (Bayern (Niederbayern/Oberpfalz und Schwaben))", "KKframe": "0000100"},
    {"value": "100", "name": "Techniker Krankenkasse", "KKframe": "0000100"},
    {"value": "215", "name": "TUI BKK", "KKframe": "0000100"},
    {"value": "233", "name": "VIACTIV Krankenkasse", "KKframe": "0000100"},
    {"value": "257", "name": "WMF BKK", "KKframe": "0000100"},

],

"SSNother": [
    {"value": "", "name": ""},
    {"value": "0", "name": "Nein"},
    {"value": "1", "name": "Ja"},
    {"value": "-999", "name": "Das weiß ich nicht"},
],

});
