<script>

    import { settings } from './lib/stores/settings.js'; 
    import { endpoints } from './lib/stores/endpoints.js'; 
    import Header from './lib/components/header/Header.svelte';
    import Welcome from './lib/components/main/Welcome.svelte';
    import Login from './lib/components/main/Login.svelte';
    import FinalPw from './lib/components/main/FinalPW.svelte';
    import Form from './lib/components/main/Form.svelte';
    import ForgotPwd from './lib/components/main/ForgotPWD.svelte';
    import Admin from './lib/components/main/Admin.svelte';
    import Admins from './lib/components/main/Admins.svelte';
    import AdminViewUser from './lib/components/main/AdminViewUser.svelte';
    import Impressum from './lib/components/main/xImpressum.svelte';
    
   // console.log("myRef is", window.location.href); //"http://localhost:8090"

    $endpoints.EPpath =window.location.href;

</script>


<Header></Header>


{#if $settings.view === "welcome"}
    <Welcome></Welcome>
{/if}

{#if $settings.view === "login"}
    <Login></Login>
{/if}

{#if $settings.view === "finalpw"}
    <FinalPw></FinalPw>
{/if}

{#if $settings.view === "form"}
    <Form></Form>
{/if}

{#if $settings.view === "admin"}
    <Admin></Admin>
{/if}

{#if $settings.view === "adminviewuser"}
    <AdminViewUser></AdminViewUser>
{/if}

{#if $settings.view === "adminslist"}
    <Admins></Admins>
{/if}

{#if $settings.view === "impressum"}
    <Impressum></Impressum>
{/if}

{#if $settings.view === "forgotpwd"}
    <ForgotPwd></ForgotPwd>
{/if}
