<script>

    // @ts-nocheck

    import { onMount, onDestroy } from 'svelte'; 
    import { fade, fly } from 'svelte/transition';
    import { fields } from '../../stores/form.js';
    import { settings } from '../../stores/settings.js';
    import { validateChars, validateSSN, checkSSN, noSpace } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';
    import InputText from '../inputText/InputText.svelte';
    import Explanation from '../text/Explanation.svelte';
    import InputTextUnknown from '../inputText/InputTextUnknown.svelte';
    import InputSelect from '../inputSelect/InputSelect.svelte';
    import InputSelectCountries from '../inputSelect/InputSelectCountries.svelte';
    import InputDate from '../inputDate/InputDate.svelte';
    import RadioButtonGroup from '../radio/RadioButtonGroup.svelte';
    import { userObj } from '../../stores/userObj.js';
   
    export let store;
    export let labelClass = "daa-label-w400";

    let inputSSNyesNo;

    let inputSSN;
    let inputBirthDate;
    let inputBirthName;
    let inputBirthLocation;
    let inputBirthCountry;

    $:showWarning = false;
    $:checkSilently = false;
    // $:SSNAvailable = store.SSNAvailable;
    $:SSNyesNo = true;
    $:currentSSN = "";
   
   
    let yesNoOptions = [
        {id:"1", groupName:"SSNyesNo", label:"Ja",value:"yes"},
        {id:"0", groupName:"SSNyesNo", label:"Nein",value:"no"},
    ];

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleSSNyesNoInput = (val) => {
       
        showWarning = false;
        $settings.showWarningInFooter = false;
        store.SSNAvailable = val;
        saveToStore();
        updateChildren();
    }

    const handleSSNInput = (val) => {
        showWarning = false;
        $settings.showWarningInFooter = false;
        let ch = noSpace(val);
        currentSSN = ch;
        inputSSN.setValue(ch);
        store.SSN = ch;
        SSNToBirthdate();
    }

    const SSNToBirthdate = () => {

        let checkBirthdate = checkSSN (store.SSN, true);
        if (checkBirthdate !== "invalid") {
            store.birthDate = checkBirthdate;
            if (inputBirthDate) inputBirthDate.update();
        }
    }

    const getStyleInputs = (str) => {
        if (store.SSNAvailable === "no" || store.SSNAvailable === "") {
            return '';
        }
        return 'display:none;';
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        checkSilently = boolSilent;

        // check the radio button
        let checkYesNo = inputSSNyesNo.validate();
        if(checkYesNo === "invalid"){
            return "invalid";
        } 

        //radio is yes
        if(store.SSNAvailable === "yes") {
            let checkSSN = inputSSN.validate();
            if(checkSSN !== "valid") {
                return "invalid";
            } else {
                return "valid";
            }
        }

        if(store.SSNAvailable === "no") {

            let chBirthDate = "valid";
            if (inputBirthDate) { 
                chBirthDate = inputBirthDate.validate();
            }

            let chBirthName = "valid";
            if (inputBirthName) { 
                chBirthName = inputBirthName.validate();
            }

            let chBirthLocation = "valid";
            if (inputBirthLocation) { chBirthLocation = inputBirthLocation.validate();}

            if(chBirthDate ==="valid" && chBirthName === "valid"  &&  chBirthLocation === "valid") {
                saveToStore();
                return "valid";
            }
        }

        return "invalid";
    }

    export const getValue = () => {
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
       
        currentSSN = store["SSN"];
        inputSSNyesNo.setValue(store.SSNAvailable);
        if (inputSSN) inputSSN.update();
        if (inputBirthDate) inputBirthDate.update();
        if (inputBirthName) inputBirthName.update();
        if (inputBirthLocation) inputBirthLocation.update();
        if (inputBirthCountry) inputBirthCountry.update();
      
    };

    const updateChildren = () => {
        
        if (inputBirthDate) inputBirthDate.update();
        if (inputBirthName) inputBirthName.update();
        if (inputBirthLocation) inputBirthLocation.update();
        if (inputBirthCountry) inputBirthCountry.update();
    }

    const saveToStore = () => {
        
        store.SSNAvailable = inputSSNyesNo.getValue();

        if (inputBirthDate) {
            store.birthDate = inputBirthDate.getValue().birthDate;
        }
        if (inputBirthName) {
           
            let getBDChecked = inputBirthName.getChecked();
            
            if(getBDChecked) {
                store.birthName = "unknown";
            } else {
                store.birthName = inputBirthName.getValue();
            }
        };
        if (inputBirthLocation) {
           
            let getBLChecked = inputBirthLocation.getChecked();
           
            if(getBLChecked) {
                store.birthLocation = "unknown";
            } else {
                store.birthLocation = inputBirthLocation.getValue();
            }
        }
        // if (inputBirthCountry) {
        //     let resultObj = inputBirthCountry.getValue();
        //     store.birthCountry = inputBirthCountry.update();
        //     store.birthCountryCode = inputBirthCountry.update();
        // }

       
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount( () => {
        update();

    });

    onDestroy(() => {
        saveToStore();
    });

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
</script>

<div >

    <Explanation 
        fieldName="explainSSN"
        labelClass="daa-input-grid-300">
    </Explanation>

    <RadioButtonGroup bind:this={inputSSNyesNo}
        data={$fields["SSNAvailable"]}  
        fieldName="SSNAvailable"
        labelClass="daa-input-grid-300"
        store = {store}
        options = {yesNoOptions}
        callback = {handleSSNyesNoInput}>
    </RadioButtonGroup>

    {#if store.SSNAvailable === "yes"}
        <InputText bind:this={inputSSN}
            value = {store["SSN"] || ""}  
            fieldName="SSN"
            store = {store}
            labelClass="daa-input-grid-300"
            callback = {handleSSNInput}>
        </InputText>
    {/if}
        
    {#if store.SSNAvailable === "no"}

        <div  in:fly={{ y: -100, duration: 250 }} out:fade={{ duration: 20 }}>

        <div style="height:10px"></div>

        <Explanation 
            fieldName="SSN"
            labelClass="daa-input-grid-300">
        </Explanation>

        <div style="height:10px"></div>

        <InputDate bind:this={inputBirthDate}
            value = {store["birthDate"]}  
            fieldName="birthDate"
            data={$fields["birthDate"]}
            store = {store}
            labelClass="daa-input-grid-300">
        </InputDate>

        <InputTextUnknown bind:this={inputBirthName} 
            value = {store["birthName"]}  
            fieldName="birthName"
            store = {store}
            labelClass="daa-input-grid-300">
        </InputTextUnknown>

        <InputTextUnknown bind:this={inputBirthLocation}  
            value = {store["birthLocation"]}
            fieldName="birthLocation"
            store = {store}
            labelClass="daa-input-grid-300">
        </InputTextUnknown>

        <InputSelectCountries bind:this={inputBirthCountry} 
            value = {store["birthCountry"]}
            data={$fields["birthCountry"]} 
            fieldName="birthCountry"
            store = {store}
            labelClass="daa-input-grid-300"
            show="birthCountry">
        </InputSelectCountries>

        </div>

    {/if}
     
</div>



